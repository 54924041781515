.loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: rgba(255, 255, 255, 0.9);
  }
  
  .loading-logo {
    width: 150px;
    height: auto;
    margin-bottom: 10px;
  }
  