@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

// Colors
$Primary:#5DA571;
$PrimaryDark:#2C8E47;
$White:#ffffff;
$Black:#000000;
$DarkGray:#4B4764;
$MidGray:#6F708C;
$Gray:#9FA1BE;
$StrokeGray:#E2E1E8;
$Warning:#FBC868;
$Danger:#FF6262;
$DangerDark:#f15050;

// Helper Classes
.mid-gray {color: $MidGray;}
.primary-color {color: $Primary;}
.fw-med {font-weight: 500;}
.bg-primary {background: $Primary !important;}
.line-height-normal {line-height: normal !important;}
.text-danger {color: $Danger !important;}
// Typography
h1,h2,h3,h4,h5,h6{color:$DarkGray;font-weight: 400;&.medium{font-weight: 500;}}
h1{font-size: 24px;}
h2{font-size: 22px;line-height:33px;}
h3{font-size: 20px;line-height:30px;}
h4{font-size: 18px;}
h5{font-size: 16px;}
h6{font-size: 14px;}
p{font-size: 14px;line-height:22px;small{font-size: 13px;line-height: 20px;}.tiny{font-size: 12px;}}
@media only screen and (max-width: 767px) {
    h3 {
        font-size: 18px;        
    }
}

// Mixins
@mixin df-ac {
    display: flex;
    align-items: center;
}

@mixin df-ac-jc {
    @include df-ac();
    justify-content: center;
}

@mixin df-ac-jc-sb {
    @include df-ac();
    justify-content: space-between;
}

@mixin obj-fit-cover {
    @include df-ac-jc();
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

@mixin trans-all {
    transition: all .15s linear;
    -moz-transition: all .15s linear;
    -webkit-transition: all .15s linear;
}

//Buttons
.btn-primary, .btn-outline-primary, .btn-cancel, .btn-danger {
    background: $Primary;
    border-color: $Primary;
    font-size: 14px;
    padding: 12px 25px;
    @include trans-all();
    &.btn-ico {
        display: flex;
        align-items: center;
        padding-left: 22px;
        i {
            margin-right: 10px;
        }
    }
    &:hover, &:active {
        background: $PrimaryDark !important;
        border-color: $PrimaryDark !important;
    }
    &.small {
        font-size: 13px;
        padding: 6px 20px;
    }
    &.btn-empty {
        background: transparent;
        color: $Primary;
        border-color: transparent;
        &:hover {
            color: $White;
        }
    }
}

.btn-outline-primary {
    background: $White;
    color: $Primary;
    border: 1px solid;
    &:hover, &:active {
        background: $Primary !important;
        border-color: $Primary !important;
    }
}

.btn-cancel {
    background: $White;
    color: $Gray;
    border: none;
    &:hover {
        background: rgba($color: $StrokeGray, $alpha: 0.45) !important;
        color: $Gray;
    }
}

.btn-danger {
    background: $Danger;
    border-color: $Danger;
    &:hover, &:active {
        background: $DangerDark !important;
        border-color: $DangerDark !important;
    }
}

.text-link {
    font-size: inherit;
    color: $Primary;
    text-decoration: none;
    @include trans-all();
    &:hover {
        color: $PrimaryDark;
    }
}

// Main Styles
body {
    font: 400 14px/20px 'Inter', sans-serif;
    color: $DarkGray;
}

.material-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 300,
    'GRAD' 0,
    'opsz' 24
}

// Form Style
.form-label {
    font-size: 12px;
    margin-bottom: 2px;
    color: $MidGray;
}

.input-group {
    .input-group-text {
        background: $White;
        padding-left: 15px;
        border-radius: 24px 0 0 24px;
        border-right: none;
        i {
            color: $MidGray;
        }
        &+.form-control {
            border-left: 0;
            padding-left: 0;
        }
    }
    &.ico-end {
        .form-control {
            border-right: none;
        }
        .input-group-text {
            border-right: 1px solid $StrokeGray;
            border-radius: 0 24px 24px 0;
            i {
                font-size: 20px;
            }
        }
    }
}

.form-control {
    border-radius: 30px;
    height: 42px;
    border: 1px solid $StrokeGray;
    font-size: 14px;
    color: $DarkGray;
    padding-left: 15px;
    &:focus {
        outline: none;
        border-color: $StrokeGray;
        box-shadow: none;
    }
    &::placeholder {
        color: rgba($color: $DarkGray, $alpha: 0.5);
    }
    &.textarea {
        min-height: 75px;
        resize: none;
        border-radius: 10px;  
    }
    &:disabled {
        background: rgba($color: $StrokeGray, $alpha: 0.5);
        opacity: 0.5;
    }
}

.form-check {
    .form-check-input {
        &[type=checkbox] {
            width: 18px;
            height: 18px;
            border-color: rgba($color: $Gray, $alpha: 0.8);
        }
        &:checked {
            background-color: $Primary;
            border-color: $Primary;
        }
        &:focus, &:focus-visible {
            box-shadow: none;
            outline: none;
        }
        & + label {
            margin-top: 2px;
        }
    }
    &.form-switch {
        position: relative;
        width: 70px;
        .form-check-input {
            width: 70px;
            height: 28px;
            border-color: #FFC5C5;
            background: rgba($color: $Danger, $alpha: 0.12) url("../images/switch-unchecked.svg") no-repeat 2px center;
            background-size: 23px;
            &:checked {
                border-color: #AFD2B9;
                background: rgba($color: $Primary, $alpha: 0.12) url("../images/switch-checked.svg") no-repeat 43px center;
                background-size: 23px;
            }
            & + .yes {
                opacity: 0;
                color: $Primary;
                left: 14px;
                top: 6px;
            }
            &:checked + .yes {
                opacity: 1;
            }
            & + .yes + .no {
                color: $Danger;
                right: 33px;
            }
            &:checked + .yes + .no {
                opacity: 0;
            }
        }
        label {
            position: absolute;
            font-size: 12px;
            top: 8px;
            width: 0;
            pointer-events: none;
        }
        &.live {
            width: 128px;
            .form-check-input {
                width: 128px;
                height: 42px;
                background-image: url("../images/offline.svg");
                background-position: 3px center;
                background-size: 34px;
                &:checked {
                    background-image: url("../images/online.svg");
                    background-position: 88px center;
                    background-size: 34px;
                }
                & + .yes {
                    left: 28px;
                    top: 12px;
                }
                & + .yes + .no {
                    right: 72px;
                    top: 14px;
                }
            }
            label {
                top: 8px;
                font-size: 14px;
            }
        }
        &.days {
            width: 84px;
            label {
                width: 32px;
                text-align: center;
            }
            .form-check-input {
                width: 84px;
                background-image: url("../images/offline.svg");
                filter: grayscale(1);
                &:checked {
                    background-image: url("../images/online.svg");
                    background-position: 57px center;
                    filter: grayscale(0);
                }
                & + .yes {
                    left: 20px;
                    top: 6px;
                }
                & + .yes + .no {
                    right: 20px;
                    filter: grayscale(1);
                }
            }
        }
    }
}

.file-input {
    width: 100%;
    height: 75px;
    border: 1px dashed $StrokeGray;
    border-radius: 10px;
    position: relative;
    @include df-ac-jc();
    input[type=file] {
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
    label {
        color: $MidGray;
        font-size: 12px;
        @include df-ac();
        i {
            font-size: 22px;
            margin-right: 3px;
        }
    }
}

.file-info {
    font-size: 11px;
    text-align: right;
    color: $Gray;
    font-style: italic;
    width: 100%;
    display: inline-block;
}


// Left Bar Style
.left-bar {
    width: 225px;
    background: $White;
    border-right: 1px solid rgba($color: $StrokeGray, $alpha: 0.5);
    position: fixed;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: 0;
    left: 0;
    top: 0;
    z-index: 999;
    .lb-top {
        width: 100%;
        .nav-brand {
            width: 95px;
        }
    }
    .nav {
        .nav-link {
            @include df-ac();
            font-size: 13px;
            color: $MidGray;
            border-left: 3px solid transparent;
            padding: 13px 20px 13px 17px;
            i {
                font-size: 20px;
                margin-right: 10px;
            }
            &:hover,&.active {
                background: rgba($color: $Gray, $alpha: 0.15);
                border-color: $Danger;
                color: $DarkGray;
            }
        }
        &.btm {
            border-top: 1px solid $StrokeGray;
            margin-top: 30px;
            .nav-item {
                width: 100%;
                .nav-link {
                    width: 100%;
                    justify-content: space-between;
                    border: none;
                    i {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 991px) {
        width: 180px;
        .nav {
            .nav-link {
                padding: 13px;
            }
        }
    }
    @media only screen and (max-width: 767px) {
        visibility: hidden;
        width: 250px;
        left: -300px;
        transition: all .32s ease-in-out;
        -moz-transition: all .32s ease-in-out;
        -webkit-transition: all .32s ease-in-out;
        z-index: 9999;
        &.active {
            left: 0;
            visibility: visible;
        }
    }
}

// Content Section Style
.content-section {
    padding: 0 25px 0 250px;
    min-height: 100vh;
    background: #FCFCFF;
    @media only screen and (max-width: 991px) {
        padding: 0 15px 0 195px;
    }
    @media only screen and (max-width: 767px) {
        padding: 0 18px;
    }
}

.head {
    padding: 20px 0 25px;
    .nav-brand {
        width: 140px;
    }
    .menu-mobile {
        width: 40px;
        height: 34px;
        background: $Primary;
        border-radius: 4px;
        padding: 9px 8px;
        align-items: center;
        display: flex;
        justify-content: space-between;
        flex-flow: column;
        &:before, &:after {
            content: "";
            width: 100%;
            height: 2px;
            background: $White;
            border-radius: 4px;
        }
        span {
            width: 100%;
            height: 2px;
            background: $White;
            border-radius: 4px;
            
        }
    }
    form {
        width: 305px;
        .input-group {
            .input-group-text {
                background: $White;
                padding-left: 15px;
                border-radius: 24px 0 0 24px;
                border-right: none;
                &+.form-control {
                    border-left: 0;
                }
            }
        }
    }
    .profile-dd {
        .btn {
            @include df-ac();
            background: $White;
            border-radius: 30px;
            box-shadow: 0 0 4px rgba($color: $Black, $alpha: 0.15);
            padding: 5px 15px 5px 5px;
            .avatar {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 8px;
                @include obj-fit-cover();
            }
            p {
                color: $DarkGray;
                margin: 0;
            }
            &:after {
                margin-left: 8px;
                color: $DarkGray;
            }
            &.show, &.active, &:active {
                border-color: $White;
            }
        }
        .dropdown-item {
            color: $DarkGray;
            font-size: 13px;
            padding: 8px 12px;
            &:hover {
                background: rgba($color: $StrokeGray, $alpha: 0.45);
            }
        }
    }
    .notify-ico {
        color: $DarkGray;
        display: inline-flex;
        text-decoration: none;
    }
    @media only screen and (max-width: 1199px) {
        form {
            width: 80%;
        }
    }
    @media only screen and (max-width: 991px) {
        form {
            width: 100%;
        }
        .profile-dd {
            .btn {
                padding: 2px;
                p {
                    display: none;
                }
                &:after {
                    display: none;
                }
                .sb-avatar {
                    width: 32px !important;
                    height: 32px !important;
                    img {
                        width: 100% !important;
                        height: 100% !important;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 767px) {
        .live {
            position: fixed;
            margin-right: 0 !important;
            bottom: 20px;
            right: 20px;
            z-index: 999;
            &:before {
                content: "";
                position: absolute;
                left: -4px;
                top: -1px;
                width: calc(100% + 7px);
                height: calc(100% + 5px);
                background: $White;
                z-index: -9;
                border-radius: 40px;
                box-shadow: 0 0 20px rgba($color: $Black, $alpha: 0.35);
            }
        }
    }
}

.main-content {
    @media only screen and (max-width:767px) {
        padding-bottom: 60px;
    }
    .title-btm {
        @include df-ac-jc-sb;
        .search {
            width: 395px;
        }
        .btn-ico {
            padding: 8px 15px 8px 10px;
            i {
            margin-right: 6px;
            }
        }
        @media only screen and (max-width:991px) {
            .search {
                width: 270px;
            }
            .btn {
                font-size: 13px;
            }
            .btn-ico {
                i {
                    font-size: 20px;
                }
            }
        }
        @media only screen and (max-width:767px) {
            flex-flow: column;
            align-items: unset;
            .search {
                width: 100%;
                margin-bottom: 15px;
            }
            .title-btm-btn-sec {
                width: 100%;
                justify-content: space-around !important;
                .btn {
                    width: 47%;
                    margin: 0 !important;
                }
                .btn-ico {
                    justify-content: center;
                }
            }
        }
    }
    .filter-sec {
        form {
            background: rgba($color: $StrokeGray, $alpha: 0.4);
            padding: 15px;
            border-radius: 6px;
            .form-label {
                font-size: 12px;
                color: $MidGray;
                margin-bottom: 2px;
                width: 100%;
            }
            .form-check {
                .form-check-label {
                    color: $DarkGray;
                    font-size: 13px;
                    margin-left: 3px;
                    margin-top: 3px;
                }
            }
        }
        @media only screen and (max-width:991px) {
            .filter-btn-sec {
                button {
                    width: 47%;
                    margin: 0 !important;
                    padding-top: 8px;
                    padding-bottom: 8px;
                }
            }
        }
    }
    .custom-table {
        --bs-table-bg: transparent;
        thead {
            th {
                font-size: 13px;
                font-weight: normal;
                color: $MidGray;
                vertical-align: middle;
                padding: 0.2rem 0.5rem;
                &:first-child {
                    padding-left: 20px;
                }
            }
        }
        tbody {
            tr {
                background: $White;
                @include trans-all();
                &:hover {
                    background: rgba($color: $StrokeGray, $alpha: 0.1);
                    cursor: pointer;
                }
                td {
                    vertical-align: middle;
                    border-color: $StrokeGray;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    &:first-child {
                        padding-left: 20px;
                        border-left: 1px solid $StrokeGray;
                    }
                    &:last-child {
                        border-right: 1px solid $StrokeGray;
                    }
                    .thumbnail {
                        width: 46px;
                        height: 46px;
                        border-radius: 50%;
                        overflow: hidden;
                        @include obj-fit-cover();
                    }
                    .type {
                        img {
                            width: 15px;
                            margin-right: 8px;
                        }
                        @include df-ac();
                    }
                    .badge {
                        &.tag {
                            font-size: 11px;
                            font-weight: 400;
                            color: $MidGray;
                            background: rgba($color: $StrokeGray, $alpha: 0.7);
                            padding: 4px 6px;
                        }
                    }
                    .count {
                        font-size: 12px;
                        color: $MidGray;
                        margin-left: 3px;
                        cursor: pointer;
                    }
                    .rating {
                        @include df-ac();
                        color: $Warning;
                        img {
                            width: 20px;
                        }
                    }
                    .action-item {
                        a {
                            text-decoration: none;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            color: $MidGray;
                            background: rgba($color: $Primary, $alpha: 0);
                            @include trans-all();
                            span {
                                font-size: 20px;
                                font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;
                            }
                            &:hover {
                                color: $PrimaryDark;
                                background: rgba($color: $Primary, $alpha: 0.15);
                            }
                            &.danger:hover {
                                color: $Danger;
                                background: rgba($color: $Danger, $alpha: 0.15);
                            }
                        }
                    }
                    .dish {
                        @include df-ac();
                    }
                }
            }
        }
    }
    .pagination-wrap {
        @include df-ac-jc-sb();
        .pagination {
            .page-item {
                margin: 0 1px;
                .page-link {
                    border: none;
                    background: none;
                    border-radius: 4px;
                    color: $MidGray;
                    font-size: 14px;
                    padding: 4px;
                    min-width: 32px;
                    min-height: 32px;
                    @include df-ac-jc();
                    i {
                        font-variation-settings: "wght" 300;
                    }
                    &:hover {
                        background: rgba($color: $Gray, $alpha: 0.5)
                    }
                }
                &.active {
                    .page-link {
                        background: $Primary;
                        color: $White;
                    }
                }
            }
        }
        @media only screen and (max-width:767px) {
            flex-flow: column;
            margin-top: 15px;
            .pagination {
                margin-top: 15px;
            }
        }
    }
}

// Overlay Style
.overlay {
    background: rgba($color: $Black, $alpha: 0.7);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
}

// Drawer Style
.drawer {
    position: fixed;
    right: 0;
    top: 0;
    background: $White;
    z-index: 999;
    min-width: 450px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 25px 30px;
    .material-symbols-outlined {
        font-variation-settings:'wght' 300,
    }
    &.small {
        width: 540px;
    }
    .title {
        --bs-gutter-x: 0;
        border-bottom: 1px solid $StrokeGray;
        padding-bottom: 12px;
        .close {
            color: $MidGray;
            &:hover {
                color: $Danger;
            }
        }
    }
    form {
        --bs-gutter-x:0;
        .type-snippet {
            --bs-gutter-x:0;
            .snippet {
                display: inline-flex;
                background: $White;
                border: 1px solid $StrokeGray;
                border-radius: 40px;
                width: auto;
                padding: 4px;
                margin-right: 15px;
                cursor: pointer;
                span {
                    @include df-ac();
                    margin-left: 10px;
                    margin-right: 15px;
                    opacity: 0.6;
                    img {
                        width: 15px;
                        margin-right: 8px;
                    }
                }
                .ico {
                    img {
                        width: 28px;
                        &.tick {
                            display: none;
                        }
                    }
                }
                &.active {
                    background: rgba($color: $StrokeGray, $alpha: 0.6);
                    span {
                        opacity: 1;
                    }
                    .ico {
                        img {
                            &.tick {
                                display: block;
                            }
                            &.cross {
                                display: none;
                            } 
                        }
                        
                    }
                }
            }
        }
        .addon-wrap {
            background: rgba($color: $StrokeGray, $alpha: 0.5);
            padding: 12px;
            border-radius: 10px;
            margin-top: 2px;
            .form-control {
                height: 34px;
                font-size: 12px;
            }
            .danger {
                position: absolute;
                right: -32px;
                top: 4px;
                display: inline-flex;
                padding: 3px;
                text-decoration: none;
                align-items: center;
                justify-content: center;
                width: auto;
                i {
                    color: $Gray;
                    font-size: 20px;
                    @include trans-all();
                }
                &:hover {
                    i {
                        color: $Danger;
                    }
                }
            }
        }
        .btn {
            padding: 8px 20px;
            font-size: 13px;
        }
    }
    .alert-wrap {
        @include df-ac-jc();
        flex-flow: column;
        min-height: 350px;
        height: 100%;
        .ico {
            width: 78px;
        }
    }
    &.add-dish {
        width: 640px;
        @media only screen and (max-width: 991px) {
            width: 600px;
        }
        @media only screen and (max-width: 767px) {
            width: 85%;
            min-width: unset;
        }
    }
    @media only screen and (max-width: 767px) {
        padding: 15px 20px;
        form {
            .type-snippet {
                .snippet {
                    width: 100%;
                    margin-right: 0;
                    justify-content: space-between;
                    &:first-child {
                        margin-bottom: 10px;
                    }
                }
            }
            .addon-wrap {
                .row {
                    padding-bottom: 10px;
                    margin-bottom: 15px !important;
                    border-bottom: 1px solid rgba($color: $Gray, $alpha: 0.4);
                    &:last-child {
                        border: none;
                        padding-bottom: 0;
                        margin-bottom: 0 !important;
                    }
                }
                .danger {
                    top: -4px;
                    margin: 0 !important;
                    background: $White;
                    border: 1px solid rgba($color: $StrokeGray, $alpha: 0.8);
                    border-radius: 5px;
                    right: -26px;
                }
            }
            .ingrd-add-btn {
                width: 25px !important;
                height: 25px !important;
                margin-left: 10px !important;
            }
        }
    }
}

// Dish Detail Style
.dish-detail-wrap {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    min-height: 100%;
    .dish-img {
        border-radius: 10px;
        width: 100%;
        height: 200px;
        overflow: hidden;
        @include obj-fit-cover();
    }
    .type {
        @include df-ac();
        color: $DarkGray;
        font-size: 13px;
        img {
            width: 17px;
            margin-right: 8px;
        }
    }
    .rating {
        @include df-ac();
        font-weight: 500;
        font-size: 14px;
        color: $Warning;
        img {
            width: 22px;
        }
    }
    .tags {
        border-bottom: 1px solid $StrokeGray;
        span {
            background: rgba($color: $StrokeGray, $alpha: 0.7);
            padding: 4px 10px;
            font-size: 13px;
            color: rgba($color: $DarkGray, $alpha: 0.8);
            display: inline-block;
            border-radius: 6px;
            margin-right: 10px;
        }
    }
    p {
        line-height: 19px;
        .tiny {
            line-height: 16px;
        }
    }
    .addon-text {
        .text {
            @include df-ac();
            .ico {
                width: 18px;
            }
            .price {
                font-size: 13px;
                font-weight: 500;
                color: $DarkGray;
            }
        }
    }
    .bottom-bar {
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid $StrokeGray;
        margin-bottom: -10px;
        a {
            text-decoration: none;
            color: $MidGray;
            font-size: 13px;
            @include df-ac();
            i {
                font-size: 22px;
                margin-right: 5px;
            }
            &:hover {
                color: $DarkGray;
                i {
                    color: $DarkGray;
                }
            }
            &.danger {
                color: $Danger;
                i {
                    color: $Danger;
                }
                &:hover {
                    color: $DangerDark;
                    i {
                        color: $DangerDark;
                    }
                }
            }
        }
    }
    @media only screen and (max-width:767px) {
        .dish-img {
            height: 150px;
        }
        .tags {
            span {
                font-size: 12px;
                padding: 2px 8px;
            }
        }
    }
    
}

// No Data Wrap Style
.no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    min-height: 400px;
    height: calc(100vh - 210px);
    .img-block {
        width: 250px;
        height: auto;
        text-align: center;
        img {
            max-width: 100%;
        }
    }
}

// Modal Style
.modal-dialog {
    .btn-close {
        --bs-btn-close-color: $White;
        position: absolute;
        right: -20px;
        top: 0;
        background-size: 10px;
        -webkit-filter: invert(1);
        -moz-filter: invert(1);
        filter: invert(1);
        opacity: 1;
        &:focus {
            box-shadow: none;
            outline: none;
        }
    }
    .order-detail {
        @include df-ac-jc-sb();
        width: 100%;
    }
    .item-detail {
        .dish {
            @include df-ac();
            .thumbnail {
                width: 38px;
                height: 38px;
                border-radius: 50%;
                overflow: hidden;
                @include obj-fit-cover();
            }
            p {
                width: calc(100% - 38px);
                padding-left: 10px;
            }
        }
    }
    .new-order-ftr {
        @include df-ac-jc-sb();
        padding-bottom: 20px;
        flex-flow: row;
        .btn {
            width: 49%;
        }
        .btn-danger {
            background: transparent;
            border-color: transparent;
            color: $Danger;
            &:hover {
                color: $White;
            }
        }
    }
}

// Bill Toggle Style
.bill-toggle {
    .accordion-item {
        border: none;
        .accordion-button {
            background: rgba($color: $StrokeGray, $alpha: 0.4);
            border: none;
            padding: 15px;
            &:focus {
                box-shadow: none;
                outline: none;
            }
            &:after {
                background-size: 15px;
                width: 15px;
                height: 15px;
                opacity: 0.7;
            }
            h6 {
                color: $DarkGray;
            }
        }
        .accordion-body {
            background: rgba($color: $StrokeGray, $alpha: 0.4);
            border-top: 1px solid $StrokeGray;
            padding: 15px;
            border-radius: 0 0 8px 8px;
            .amount {
                @include df-ac();
                justify-content: end;
            }
        }
    }
    
}

.prep-time {
    position: relative;
    .form-label {
        position: absolute;
        top: 12px;
        left: 20px;
        margin-bottom: 0;
        pointer-events: none;
    }
    .form-select {
        padding-left: 20.5rem;
    }
}

// Nav Pills Style
.nav-pills {
    .nav-item {
        margin-right: 8px;
    }
    .nav-link {
        background: rgba($color: $StrokeGray, $alpha: 0.45);
        color: $Gray;
        font-size: 13px;
        border-radius: 40px;
        &.active {
            background: $Primary;
        }
    }
    &.order-pills {
        @media only screen and (max-width:1199px) {
            display: flex;
            flex-wrap: wrap;
            flex-flow: row;
            width: 100%;
            overflow-x: auto;
            padding-bottom: 10px;
            .nav-item {
                .nav-link {
                    white-space: nowrap;
                }
            }
        }
    }
}

// Order Snippet Style
.order-snippet {
    background: $White;
    border-radius: 10px;
    border: 1px solid $StrokeGray;
    padding: 20px 30px;
    .os-head {
        @include df-ac-jc-sb();
        .order-by {
            @include df-ac();
            .avatar {
                @include df-ac();
                .thumbnail {
                    @include obj-fit-cover();
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    overflow: hidden;
                }
                p {
                    line-height: 14px;
                }
            }
            .action-item {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                overflow: hidden;
                color: $Primary;
                text-decoration: none;
                @include df-ac-jc();
                @include trans-all();
                span {
                    font-size: 20px;
                }
                &:hover {
                    background: rgba($color: $Primary, $alpha: 0.15);
                }
            }
        }
    }
    .os-body {
        .bg-blk {
            background: rgba($color: $StrokeGray, $alpha: 0.45);
            border-radius: 6px;
            padding: 20px;
            .row {
                &:last-child {
                    margin-bottom: 0 !important;
                }
            }
            .dish {
                @include df-ac();
                .thumbnail {
                    width: 38px;
                    height: 38px;
                    border-radius: 50%;
                    overflow: hidden;
                    @include obj-fit-cover();
                }
            }
            p {
                line-height: 19px;
            }
        }
        .accordion-button {
            padding: 20px;
            h6 {
                @include df-ac-jc-sb();
                width: calc(100% - 30px);
            }
        }
        .ride {
            @include df-ac();
            p {
                line-height: 16px;
            }
            .avatar {
                width: 34px;
                height: 34px;
                border-radius: 50%;
                overflow: hidden;
                @include obj-fit-cover();
            }
        }
        .badge {
            &.rating {
                background: $Warning;
                font-size: 14px;
                display: inline-flex;
                align-items: center;
                padding-left: 3px;
                img {
                    filter: brightness(0) invert(1);
                }
            }
        }
        p {
            margin-top: 5px;
            line-height: 15px;
        }
    }
    @media only screen and (max-width:1199px) { 
        padding: 20px;
    }
    @media only screen and (max-width:767px) { 
        padding: 12px;
        .os-head {
            flex-flow: column;
            align-items: flex-start;
            .order-by {
                .avatar {
                    margin-top: 10px;
                    .thumbnail {
                        display: none;
                    }
                }
            }
        }
        .os-body {
            .bg-blk {
                padding: 13px;
                .dish {
                    .thumbnail {
                        display: none;
                    }
                }
            }
        }
    }
}

// Dashboard Style
.dashboard-section {
    .anime, .btm-same-height {
        height: 100%;
    }
    .recent-order {
        height: 476px;
        .ro-snippet-wrap {
            height: calc(100% - 35px);
            overflow-y: auto;
            .card-wrap {
                .order-id {
                    font-size: 12px;
                    line-height: 12px;
                    font-weight: 500;
                    color: $DarkGray;
                    margin-bottom: 7px;
                    span {
                        display: inline-flex;
                        align-items: center;
                        &:last-child {
                            &:after {
                                display: none;
                            }
                        }
                        &:after {
                            content: "";
                            width: 3px;
                            height: 3px;
                            border-radius: 50%;
                            background: $MidGray;
                            display: inline-block;
                            margin: 0 5px;  
                        }
                    }
                }
                .card {
                    padding: 10px 15px;
                    text-decoration: none;
                    border-color: rgba($color: $StrokeGray, $alpha: 0.7);
                    @include trans-all();
                    .item {
                        display: block;
                        width: 100%;
                        font-size: 12px;
                        color: $MidGray;
                    }
                    .go-ico {
                        font-size: 20px;
                        color: $MidGray;
                        margin-left: 5px;
                    }
                    &:hover {
                        background: rgba($color: $StrokeGray, $alpha: 0.3);
                    }
                    .badge {
                        min-width: 80px;
                    }
                }
            }
        }
    }
    .snippet-content {
        .dish-card {
            @include df-ac-jc-sb();
            width: 100%;
            .dish {
                @include df-ac();
                width: 75%;
                .thumbnail {
                    width: 58px;
                    height: 58px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-right: 12px;
                    @include obj-fit-cover();
                }
                p {
                    line-height: 14px;
                    display: block;
                    width: 100%;
                }
                .category-ico {
                    width: 14px;
                    margin-bottom: 3px;
                }
                .rating {
                    @include df-ac();
                    font-size: 11px;
                    font-weight: 500;
                    color: $Warning;
                    margin-top: -2px;
                    .ico {
                        width: 8px;
                        margin-right: 3px;
                    }
                    &.with-name {
                        font-size: 12px;
                        span {
                            font-size: 13px;
                            font-weight: 500;
                            color: $DarkGray;
                            margin-right: 5px;
                        }
                    }
                }
                .content {
                    width: calc(100% - 70px);
                    .review {
                        font-size: 11px;
                        color: $MidGray;
                        line-height: 15px;
                        display: inline-block;
                        height: 30px;
                        overflow: hidden;
                    }
                }
            }
            .price {
                width: 25%;
            }
        }
    }
    @media only screen and (max-width: 1280px) {
        .recent-order {
            height: 498px;
        }
    }
    @media only screen and (max-width: 767px) {
        .anime {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex-flow: row-reverse;
            .ico-wrap {
                margin-bottom: 0;
            }
            .count-cont {
                width: calc(100% - 42px);
                padding-right: 20px;
            }
        }
    }
}

.snippet-block {
    width: 100%;
    background: $White;
    border: 1px solid rgba($color: $StrokeGray, $alpha: 0.7);
    border-radius: 10px;
    padding: 20px;
    .ico-wrap {
        width: 42px;
        height: 42px;
        background: rgba($color: $StrokeGray, $alpha: 0.45);
        border-radius: 50%;
        margin-bottom: 40px;
        @include df-ac-jc();
        span {
            font-size: 22px;
            color: $MidGray;
        }
    }
    .count {
        h4 {
            color: $DarkGray;
            @include df-ac();
            i {
                font-size: 20px;
                margin-left: 3px;
                &.up {
                    color: $Primary;
                }
                &.fall {
                    color: $Danger;
                }
            }
        }
    }
    .head {
        padding: 0;
        margin-bottom: 18px;
        @include df-ac-jc-sb();
        .text-link {
            @include df-ac();
            color: $MidGray;
            font-size: 11px;
            text-transform: uppercase;
            @include trans-all();
            i {
                font-size: 18px;
                margin-left: 3px;
            }
            &:hover {
                color: $Primary;
            }
        }
        h6 {
            small {
                font-size: 12px;
                color: $Gray;
                display: block;
            }
        }
    }
    &.anime {
        box-shadow: 0 0 0 rgba($color: $Black, $alpha: 0);
        cursor: pointer;
        transition: all .2s linear;
        -moztransition: all .2s linear;
        -webkit-transition: all .2s linear;
        &:hover {
            box-shadow: 0 4px 4px rgba($color: $Black, $alpha: 0.12);
            transform: scale(1.02);
        }
        &.blue {
            .ico-wrap {
                background: #DDECFF;
                span {
                    color: #3E94FF;
                }
            }
            &:hover {
                background: #3E94FF;
                border-color: #3E94FF;
                .ico-wrap {
                    background: $White;
                }
                h4, i, p {
                    color: $White;
                }
            }
        }
        &.green {
            .ico-wrap {
                background: #CFF8ED;
                span {
                    color: $Primary;
                }
            }
            &:hover {
                background: $Primary;
                border-color: $Primary;
                .ico-wrap {
                    background: $White;
                }
                h4, i, p {
                    color: $White;
                }
            }
        }
        &.red {
            .ico-wrap {
                background: #FDE2E2;
                span {
                    color: $Danger;
                }
            }
            &:hover {
                background: #ff8484;
                border-color: #ff8484;
                .ico-wrap {
                    background: $White;
                }
                h4, i, p {
                    color: $White;
                }
            }
        }
    }
    .no-data {
        height: auto;
        min-height: 260px;
    }
}

.badge.status {
    background: rgba($color: $StrokeGray, $alpha: 0.45);
    color: $MidGray;
    font-size: 10px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 4px 10px;
    border-radius: 4px;
    &.preparing {
        color: #f4a413;
        background: rgba($color: #FEBA3E, $alpha: 0.15);
    }
    &.ready {
        color: #3498DB;
        background: rgba($color: #3498DB, $alpha: 0.15);
    }
    &.pickedup {
        color: #9B59B6;
        background: rgba($color: #9B59B6, $alpha: 0.15);
    }
    &.delivered {
        color: $Primary;
        background: rgba($color: $Primary, $alpha: 0.15);
    }
    &.returned {
        color: $Danger;
        background: rgba($color: $Danger, $alpha: 0.15);
    }
    &.rejected {
        color: #4A6682;
        background: rgba($color: #4A6682, $alpha: 0.15);
    }
}

// Settlement Style
.settlement-content {
    .change-btn {
        font-size: 13px;
        padding: 5px;
        color: $MidGray;
        i {
            font-size: 20px;
        }
        &:hover {
            background: transparent !important;
            border-color: transparent !important;
            color: $Primary;
        }
    }
    .add-bank {
        @include df-ac-jc();
        text-decoration: none;
        font-size: 13px;
        color: $Primary;
        height: 100%;
        border-radius: 10px;
        @include trans-all();
        &:hover {
            background: rgba($color: $Primary, $alpha: 0.12);
            color: $Primary;
        }
    }
    .snippet-block {
        &.empty {
            padding: 0;
        }
        .custom-table {
            thead {
                th:last-child {
                    padding-right: 20px;
                }
            }
            tbody {
                tr {
                    td:last-child {
                        padding-right: 20px;
                    }
                    td {
                        background: rgba($color: $StrokeGray, $alpha: 0.2);
                        padding-top: 18px;
                        padding-bottom: 18px;
                    }
                    p {
                        font-size: 13px;
                        color: $MidGray;
                    }
                }
            }
            
        }
    }
    .logo-wrap {
        width: 100px;
        height: 35px;
        justify-content: flex-end;
        display: flex;
    }
}

.settlement-snippet {
    @include trans-all();
    cursor: pointer;
    img, p {
        opacity: 0.5;
    }
    .tick-ico {
        filter: grayscale(1);
        opacity: 0.3;
    }
    &.selected {
        img, p {
            opacity: 1;
        }
        .tick-ico {
            opacity: 1;
            filter: grayscale(0);
        }
    }
    &:hover {
        border-color: $Primary;
    }
}

.wallet-snippet {
    background: url("../images/wallet-bg.svg") no-repeat 0 0;
    background-size: cover;
    min-height: 240px;
    border-radius: 10px;
    width: 100%;
    padding: 30px;
    color: $White;
    @include df-ac-jc-sb();
    .amount-text {
        font-weight: 500;
        font-size: 32px;
    }
    .info-ico {
        font-size: 20px;
        cursor: pointer;
    }
    .withdraw-btn {
        padding: 10px 38px;
        background: rgba($color: $White, $alpha: 0.25);
        border-color: $White;
        &:hover {
            background: rgba($color: $White, $alpha: 1) !important;
            border-color: $White !important;
            color: $Primary;
        }
    }
    
}

// Tooltip Style
.tooltip {
    .tooltip-inner {
        font-size: 12px;
        color: $StrokeGray;
    }
}

// Add Bank Drawer Style
.add-bank-form {
    min-height: calc(100vh - 125px);
    padding-bottom: 58px;
    position: relative;
    .btn-bottom {
        position: absolute;
        bottom: 0;
        right: 0;
        background: $White;
        padding-top: 20px;
    }
    .radio-btn-row {
        display: flex;
        margin-top: 12px;
        .form-check {
            width: auto;
            margin-right: 20px;
            label {
                font-size: 14px;
            }
        }
    }
}

// Settings Styles
.settings-content {
    .cover-photo {
        width: calc(100% + 2px);
        height: 210px;
        border-radius: 10px 10px 0 0;
        overflow: hidden;
        margin: -1px 0px 0 -1px;
        position: relative;
        @include obj-fit-cover();
        .btn-edit {
            position: absolute;
            top: 20px;
            right: 20px;
            border-radius: 30px;
            border: 1px solid $White;
            background: rgba($color: $White, $alpha: 0.4);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px 15px;
            font-size: 13px;
            color: $White;
            z-index: 9;
            @include trans-all();
            i {
                font-size: 20px;
                margin-right: 5px;
            }
            &:before {
                content: "";
                background: rgba($color: $Black, $alpha: 0.4);
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border-radius: 30px;
                z-index: -9;
            }
            &:hover {
                background: rgba($color: $White, $alpha: 0.15);
            }
        }
    }
    .text-link {
        font-size: 12px;
    }
    .cover-cont {
        padding: 35px;
    }
    .profile-pic-wrap {
        position: relative;
        z-index: 99;
        margin-top: -100px;
        width: 132px;
        .pic-wrap {
            width: 132px;
            height: 132px;
            border-radius: 50%;
            overflow: hidden;
            border: 2px solid $White;
            background: $White;
            @include obj-fit-cover();
            box-shadow: 0 0 8px rgba($color: $Black, $alpha: 0.09);
            .txt {
                font-size: 32px;
                font-weight: 500;
                color: rgba($color: $Gray, $alpha: 0.5);
            }
        }
        .btn-circle {
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 38px;
            height: 38px;
            border-radius: 50%;
            i {
                font-size: 22px;
            }
        }
    }
    .personal-detail {
        @include df-ac-jc-sb();
        .list-inline {
            .list-inline-item {
                display: inline-flex;
                align-items: center;
                font-size: 13px;
                color: $MidGray;
                i {
                    font-size: 20px;
                    margin-right: 5px;
                }
                &:first-child {
                    &:after {
                        content: "";
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        background: rgba($color: $MidGray, $alpha: 0.7);
                        display: inline-block;
                        margin-left: 10px;
                    }
                }
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }
    }
    .empty-wrap {
        @include df-ac-jc-sb();
        background: rgba($color: $StrokeGray, $alpha: 0.5);
        padding: 20px;
        border-radius: 6px;
        color: $Gray;
        font-size: 13px;
        text-decoration: none;
        font-style: italic;
        @include trans-all();
        &:hover {
            color: $MidGray;
        }
    }
    .progress-count {
        background: $Primary;
        border-radius: 4px;
        color: $White;
        font-size: 13px;
        font-weight: 600;
        width: 62px;
        height: 32px;
        @include df-ac-jc();
    }
    .progress-list {
        margin-top: 30px;
        margin-bottom: 0;
        li {
            @include df-ac-jc-sb();
            color: $MidGray;
            font-size: 12px;
            margin-bottom: 18px;
            &:last-child {
                margin-bottom: 0;
            }
            .ico {
                width: 20px;
                margin-right: 7px;
                filter: grayscale(1);
                opacity: 0.35;
            }
            .level {
                color: $Gray;
                padding-right: 15px;
            }
            &.done {
                color: $DarkGray;
                .level {
                    color: $DarkGray;
                }
                .ico {
                    opacity: 1;
                    filter: grayscale(0);
                }
            }
        }
    }
    .head-line {
        @include df-ac-jc-sb();
        border-bottom: 1px solid $StrokeGray;
        padding-top: 12px;
        padding-bottom: 12px;
        margin-bottom: 15px;
    }
    .short-bio {
        p {
            line-height: 18px;
            margin-bottom: 12px;
            .tiny {
                color: $MidGray;
                line-height: inherit;
            }
        }
    }
    .your-cuisine {
        li {
            background: rgba($color: $StrokeGray, $alpha: 0.7);
            color: rgba($color: $DarkGray, $alpha: 0.8);
            padding: 5px 10px;
            font-size: 13px;
            border-radius: 6px;
        }
    }
    .snippet-block {
        .text-link {
            font-size: 12px;
            text-transform: none;
            color: $Primary;
            &:hover {
                color: $PrimaryDark;
            }
        }
    }
}

.pwd-criteria-list {
    padding: 0;
    list-style-position: inside;
    li {
        font-size: 13px;
        color: $MidGray;
        margin-bottom: 10px;
    }
}

.top-header {
    padding-left: 235px;
    padding-right: 15px;
    margin-left: 0;
    margin-right: 0;
    background: #FCFCFF;
    @media only screen and (max-width: 991px) {
        padding-left: 180px;
        padding-right: 0;
    }
    @media only screen and (max-width: 767px) {
        padding-left: 5px;
        padding-right: 5px;
    }
}

.menu-backdrop {
    background: rgba($color: $Black, $alpha: 0.75);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    transition: all .32s ease-in-out;
    &.active {
        opacity: 1;
        visibility: visible;
    }
}

.custom-table.table>:not(:last-child)>:last-child>* {
    border-bottom: 1px solid $StrokeGray;
}

.table-wrap {
    @media only screen and (max-width:1199px) {
        width: 100%;
        overflow-x: auto;
        .custom-table {
            min-width: 980px;
        }
    }
}

.modal {
    @media only screen and (max-width:767px) {
        .modal-dialog {
            margin-top: 5%;
            margin-left: 6%;
            margin-right: 6%;
            .btn-close {
                right: -17px;
            }
            .modal-title {
                font-size: 18px;
            }
        }
    }
}



.new-order {
    .new-order-overlay {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: $Black, $alpha: 0.8);
        z-index: -9;
    }
    @media only screen and (max-width:767px) {
        .modal-header {
            padding-top: 10px;
            padding-bottom: 10px;
        }
        .modal-body {
            h6 {
                font-size: 13px;
            }
            .dish {
                .thumbnail {
                    display: none;
                }
                p {
                    padding-left: 0;
                    width: 100%;
                }
            }
            .prep-time {
                .form-select {
                    padding-left: 9.8rem;
                    font-size: 13px;
                    text-align: right;
                }
            }
        }
        .new-order-ftr {
            padding-top: 0;
            flex-flow: column-reverse;
            .btn {
                width: 100%;
            }
        }
    }
}

.fp-input {
    width: 360px;
    @media only screen and (max-width:767px) {
        width: calc(100vw - 44px);
    }
}

.input-btn {
    cursor: pointer;
}