@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
.mid-gray {
  color: #6F708C;
}

.primary-color {
  color: #5DA571;
}

.fw-med {
  font-weight: 500;
}

.bg-primary {
  background: #5DA571 !important;
}

.line-height-normal {
  line-height: normal !important;
}

.text-danger {
  color: #FF6262 !important;
}

h1, h2, h3, h4, h5, h6 {
  color: #4B4764;
  font-weight: 400;
}
h1.medium, h2.medium, h3.medium, h4.medium, h5.medium, h6.medium {
  font-weight: 500;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 22px;
  line-height: 33px;
}

h3 {
  font-size: 20px;
  line-height: 30px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

p {
  font-size: 14px;
  line-height: 22px;
}
p small {
  font-size: 13px;
  line-height: 20px;
}
p .tiny {
  font-size: 12px;
}

.btn-primary, .btn-outline-primary, .btn-cancel, .btn-danger {
  background: #5DA571;
  border-color: #5DA571;
  font-size: 14px;
  padding: 12px 25px;
  transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
}
.btn-primary.btn-ico, .btn-outline-primary.btn-ico, .btn-cancel.btn-ico, .btn-danger.btn-ico {
  display: flex;
  align-items: center;
  padding-left: 22px;
}
.btn-primary.btn-ico i, .btn-outline-primary.btn-ico i, .btn-cancel.btn-ico i, .btn-danger.btn-ico i {
  margin-right: 10px;
}
.btn-primary:hover, .btn-primary:active, .btn-outline-primary:hover, .btn-outline-primary:active, .btn-cancel:hover, .btn-cancel:active, .btn-danger:hover, .btn-danger:active {
  background: #2C8E47 !important;
  border-color: #2C8E47 !important;
}
.btn-primary.small, .btn-outline-primary.small, .btn-cancel.small, .btn-danger.small {
  font-size: 13px;
  padding: 6px 20px;
}
.btn-primary.btn-empty, .btn-outline-primary.btn-empty, .btn-cancel.btn-empty, .btn-danger.btn-empty {
  background: transparent;
  color: #5DA571;
  border-color: transparent;
}
.btn-primary.btn-empty:hover, .btn-outline-primary.btn-empty:hover, .btn-cancel.btn-empty:hover, .btn-danger.btn-empty:hover {
  color: #ffffff;
}

.btn-outline-primary {
  background: #ffffff;
  color: #5DA571;
  border: 1px solid;
}
.btn-outline-primary:hover, .btn-outline-primary:active {
  background: #5DA571 !important;
  border-color: #5DA571 !important;
}

.btn-cancel {
  background: #ffffff;
  color: #9FA1BE;
  border: none;
}
.btn-cancel:hover {
  background: rgba(226, 225, 232, 0.45) !important;
  color: #9FA1BE;
}

.btn-danger {
  background: #FF6262;
  border-color: #FF6262;
}
.btn-danger:hover, .btn-danger:active {
  background: #f15050 !important;
  border-color: #f15050 !important;
}

.text-link {
  font-size: inherit;
  color: #5DA571;
  text-decoration: none;
  transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
}
.text-link:hover {
  color: #2C8E47;
}

body {
  font: 400 14px/20px "Inter", sans-serif;
  color: #4B4764;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;
}

.form-label {
  font-size: 12px;
  margin-bottom: 2px;
  color: #6F708C;
}

.input-group .input-group-text {
  background: #ffffff;
  padding-left: 15px;
  border-radius: 24px 0 0 24px;
  border-right: none;
}
.input-group .input-group-text i {
  color: #6F708C;
}
.input-group .input-group-text + .form-control {
  border-left: 0;
  padding-left: 0;
}
.input-group.ico-end .form-control {
  border-right: none;
}
.input-group.ico-end .input-group-text {
  border-right: 1px solid #E2E1E8;
  border-radius: 0 24px 24px 0;
}
.input-group.ico-end .input-group-text i {
  font-size: 20px;
}

.form-control {
  border-radius: 30px;
  height: 42px;
  border: 1px solid #E2E1E8;
  font-size: 14px;
  color: #4B4764;
  padding-left: 15px;
}
.form-control:focus {
  outline: none;
  border-color: #E2E1E8;
  box-shadow: none;
}
.form-control::-moz-placeholder {
  color: rgba(75, 71, 100, 0.5);
}
.form-control::placeholder {
  color: rgba(75, 71, 100, 0.5);
}
.form-control.textarea {
  min-height: 75px;
  resize: none;
  border-radius: 10px;
}
.form-control:disabled {
  background: rgba(226, 225, 232, 0.5);
  opacity: 0.5;
}

.form-check .form-check-input[type=checkbox] {
  width: 18px;
  height: 18px;
  border-color: rgba(159, 161, 190, 0.8);
}
.form-check .form-check-input:checked {
  background-color: #5DA571;
  border-color: #5DA571;
}
.form-check .form-check-input:focus, .form-check .form-check-input:focus-visible {
  box-shadow: none;
  outline: none;
}
.form-check .form-check-input + label {
  margin-top: 2px;
}
.form-check.form-switch {
  position: relative;
  width: 70px;
}

.form-check.form-switch .form-check-input + .yes {
  opacity: 0;
  color: #5DA571;
  left: 14px;
  top: 6px;
}
.form-check.form-switch .form-check-input:checked + .yes {
  opacity: 1;
}
.form-check.form-switch .form-check-input + .yes + .no {
  color: #FF6262;
  right: 33px;
}
.form-check.form-switch .form-check-input:checked + .yes + .no {
  opacity: 0;
}
.form-check.form-switch label {
  position: absolute;
  font-size: 12px;
  top: 8px;
  width: 0;
  pointer-events: none;
}
.form-check.form-switch.live {
  width: 128px;
}

.form-check.form-switch.live .form-check-input + .yes {
  left: 28px;
  top: 12px;
}
.form-check.form-switch.live .form-check-input + .yes + .no {
  right: 72px;
  top: 14px;
}
.form-check.form-switch.live label {
  top: 8px;
  font-size: 14px;
}
.form-check.form-switch.days {
  width: 84px;
}
.form-check.form-switch.days label {
  width: 32px;
  text-align: center;
}

.form-check.form-switch.days .form-check-input + .yes {
  left: 20px;
  top: 6px;
}
.form-check.form-switch.days .form-check-input + .yes + .no {
  right: 20px;
  filter: grayscale(1);
}

.file-input {
  width: 100%;
  height: 75px;
  border: 1px dashed #E2E1E8;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.file-input input[type=file] {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.file-input label {
  color: #6F708C;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.file-input label i {
  font-size: 22px;
  margin-right: 3px;
}

.file-info {
  font-size: 11px;
  text-align: right;
  color: #9FA1BE;
  font-style: italic;
  width: 100%;
  display: inline-block;
}

.left-bar {
  width: 225px;
  background: #ffffff;
  border-right: 1px solid rgba(226, 225, 232, 0.5);
  position: fixed;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 0;
}
.left-bar .lb-top {
  width: 100%;
}
.left-bar .lb-top .nav-brand {
  width: 95px;
}
.left-bar .nav .nav-link {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #6F708C;
  border-left: 3px solid transparent;
  padding: 13px 20px 13px 17px;
}
.left-bar .nav .nav-link i {
  font-size: 20px;
  margin-right: 10px;
}
.left-bar .nav .nav-link:hover, .left-bar .nav .nav-link.active {
  background: rgba(159, 161, 190, 0.15);
  border-color: #FF6262;
  color: #4B4764;
}
.left-bar .nav.btm {
  border-top: 1px solid #E2E1E8;
  margin-top: 30px;
}
.left-bar .nav.btm .nav-item {
  width: 100%;
}
.left-bar .nav.btm .nav-item .nav-link {
  width: 100%;
  justify-content: space-between;
  border: none;
}
.left-bar .nav.btm .nav-item .nav-link i {
  margin-right: 0;
}
@media only screen and (max-width: 991px) {
  .left-bar {
    width: 180px;
  }
  .left-bar .nav .nav-link {
    padding: 13px;
  }
}

.content-section {
  padding: 0 25px 0 250px;
  min-height: 100vh;
  background: #FCFCFF;
}
@media only screen and (max-width: 991px) {
  .content-section {
    padding: 0 15px 0 195px;
  }
}
@media only screen and (max-width: 767px) {
  .content-section {
    padding: 0 18px;
  }
}

.head {
  padding: 20px 0 25px;
}
.head .nav-brand {
  width: 140px;
}
.head .menu-mobile {
  width: 40px;
  height: 34px;
  background: #5DA571;
  border-radius: 4px;
  padding: 9px 8px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-flow: column;
}
.head .menu-mobile:before, .head .menu-mobile:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #ffffff;
  border-radius: 4px;
}
.head .menu-mobile span {
  width: 100%;
  height: 2px;
  background: #ffffff;
  border-radius: 4px;
}
.head form {
  width: 395px;
}
.head form .input-group .input-group-text {
  background: #ffffff;
  padding-left: 15px;
  border-radius: 24px 0 0 24px;
  border-right: none;
}
.head form .input-group .input-group-text + .form-control {
  border-left: 0;
}
.head .profile-dd .btn {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 30px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  padding: 5px 15px 5px 5px;
}
.head .profile-dd .btn .avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.head .profile-dd .btn .avatar img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.head .profile-dd .btn p {
  color: #4B4764;
  margin: 0;
}
.head .profile-dd .btn:after {
  margin-left: 8px;
  color: #4B4764;
}
.head .profile-dd .btn.show, .head .profile-dd .btn.active, .head .profile-dd .btn:active {
  border-color: #ffffff;
}
.head .profile-dd .dropdown-item {
  color: #4B4764;
  font-size: 13px;
  padding: 8px 12px;
}
.head .profile-dd .dropdown-item:hover {
  background: rgba(226, 225, 232, 0.45);
}
.head .notify-ico {
  color: #4B4764;
  display: inline-flex;
  text-decoration: none;
}
@media only screen and (max-width: 1199px) {
  .head form {
    width: 80%;
  }
}
@media only screen and (max-width: 991px) {
  .head form {
    width: 100%;
  }
  .head .profile-dd .btn {
    padding: 2px;
  }
  .head .profile-dd .btn p {
    display: none;
  }
  .head .profile-dd .btn:after {
    display: none;
  }
  .head .profile-dd .btn .sb-avatar {
    width: 32px !important;
    height: 32px !important;
  }
  .head .profile-dd .btn .sb-avatar img {
    width: 100% !important;
    height: 100% !important;
  }
}
@media only screen and (max-width: 767px) {
  .head .live {
    position: fixed;
    margin-right: 0 !important;
    bottom: 20px;
    right: 20px;
    z-index: 999;
  }
  .head .live:before {
    content: "";
    position: absolute;
    left: -4px;
    top: -1px;
    width: calc(100% + 7px);
    height: calc(100% + 5px);
    background: #ffffff;
    z-index: -9;
    border-radius: 40px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.35);
  }
}

.main-content .title-btm {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-content .title-btm .search {
  width: 395px;
}
.main-content .title-btm .btn-ico {
  padding: 8px 15px 8px 10px;
}
.main-content .title-btm .btn-ico i {
  margin-right: 6px;
}
.main-content .filter-sec form {
  background: rgba(226, 225, 232, 0.4);
  padding: 15px;
  border-radius: 6px;
}
.main-content .filter-sec form .form-label {
  font-size: 12px;
  color: #6F708C;
  margin-bottom: 2px;
  width: 100%;
}
.main-content .filter-sec form .form-check .form-check-label {
  color: #4B4764;
  font-size: 13px;
  margin-left: 3px;
  margin-top: 3px;
}
.main-content .custom-table {
  --bs-table-bg: transparent;
}
.main-content .custom-table thead th {
  font-size: 13px;
  font-weight: normal;
  color: #6F708C;
  vertical-align: middle;
  padding: 0.2rem 0.5rem;
}
.main-content .custom-table thead th:first-child {
  padding-left: 20px;
}
.main-content .custom-table tbody tr {
  background: #ffffff;
  transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
}
.main-content .custom-table tbody tr:hover {
  background: rgba(226, 225, 232, 0.1);
  cursor: pointer;
}
.main-content .custom-table tbody tr td {
  vertical-align: middle;
  border-color: #E2E1E8;
  padding-top: 15px;
  padding-bottom: 15px;
}
.main-content .custom-table tbody tr td:first-child {
  padding-left: 20px;
  border-left: 1px solid #E2E1E8;
}
.main-content .custom-table tbody tr td:last-child {
  border-right: 1px solid #E2E1E8;
}
.main-content .custom-table tbody tr td .thumbnail {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-content .custom-table tbody tr td .thumbnail img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.main-content .custom-table tbody tr td .type {
  display: flex;
  align-items: center;
}
.main-content .custom-table tbody tr td .type img {
  width: 15px;
  margin-right: 8px;
}
.main-content .custom-table tbody tr td .badge.tag {
  font-size: 11px;
  font-weight: 400;
  color: #6F708C;
  background: rgba(226, 225, 232, 0.7);
  padding: 4px 6px;
}
.main-content .custom-table tbody tr td .count {
  font-size: 12px;
  color: #6F708C;
  margin-left: 3px;
  cursor: pointer;
}
.main-content .custom-table tbody tr td .rating {
  display: flex;
  align-items: center;
  color: #FBC868;
}
.main-content .custom-table tbody tr td .rating img {
  width: 20px;
}
.main-content .custom-table tbody tr td .action-item a {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #6F708C;
  background: rgba(93, 165, 113, 0);
  transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
}
.main-content .custom-table tbody tr td .action-item a span {
  font-size: 20px;
  font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;
}
.main-content .custom-table tbody tr td .action-item a:hover {
  color: #2C8E47;
  background: rgba(93, 165, 113, 0.15);
}
.main-content .custom-table tbody tr td .action-item a.danger:hover {
  color: #FF6262;
  background: rgba(255, 98, 98, 0.15);
}
.main-content .custom-table tbody tr td .dish {
  display: flex;
  align-items: center;
}
.main-content .pagination-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-content .pagination-wrap .pagination .page-item {
  margin: 0 1px;
}
.main-content .pagination-wrap .pagination .page-item .page-link {
  border: none;
  background: none;
  border-radius: 4px;
  color: #6F708C;
  font-size: 14px;
  padding: 4px;
  min-width: 32px;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-content .pagination-wrap .pagination .page-item .page-link i {
  font-variation-settings: "wght" 300;
}
.main-content .pagination-wrap .pagination .page-item .page-link:hover {
  background: rgba(159, 161, 190, 0.5);
}
.main-content .pagination-wrap .pagination .page-item.active .page-link {
  background: #5DA571;
  color: #ffffff;
}

.overlay {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

/* .drawer {
  position: fixed;
  right: 0;
  top: 0;
  background: #ffffff;
  z-index: 999;
  min-width: 450px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 25px 30px;
}
.drawer .material-symbols-outlined {
  font-variation-settings: "wght" 300;
}
.drawer.small {
  width: 540px;
}
.drawer .title {
  --bs-gutter-x: 0;
  border-bottom: 1px solid #E2E1E8;
  padding-bottom: 12px;
}
.drawer .title .close {
  color: #6F708C;
}
.drawer .title .close:hover {
  color: #FF6262;
}
.drawer form {
  --bs-gutter-x:0;
}
.drawer form .type-snippet {
  --bs-gutter-x:0;
}
.drawer form .type-snippet .snippet {
  display: inline-flex;
  background: #ffffff;
  border: 1px solid #E2E1E8;
  border-radius: 40px;
  width: auto;
  padding: 4px;
  margin-right: 15px;
  cursor: pointer;
}
.drawer form .type-snippet .snippet span {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 15px;
  opacity: 0.6;
}
.drawer form .type-snippet .snippet span img {
  width: 15px;
  margin-right: 8px;
}
.drawer form .type-snippet .snippet .ico img {
  width: 28px;
}
.drawer form .type-snippet .snippet .ico img.tick {
  display: none;
}
.drawer form .type-snippet .snippet.active {
  background: rgba(226, 225, 232, 0.6);
}
.drawer form .type-snippet .snippet.active span {
  opacity: 1;
}
.drawer form .type-snippet .snippet.active .ico img.tick {
  display: block;
}
.drawer form .type-snippet .snippet.active .ico img.cross {
  display: none;
}
.drawer form .addon-wrap {
  background: rgba(226, 225, 232, 0.5);
  padding: 12px;
  border-radius: 10px;
  margin-top: 2px;
}
.drawer form .addon-wrap .form-control {
  height: 34px;
  font-size: 12px;
}
.drawer form .addon-wrap .danger {
  position: absolute;
  right: -32px;
  top: 4px;
  display: inline-flex;
  padding: 3px;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  width: auto;
}
.drawer form .addon-wrap .danger i {
  color: #9FA1BE;
  font-size: 20px;
  transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
}
.drawer form .addon-wrap .danger:hover i {
  color: #FF6262;
}
.drawer form .btn {
  padding: 8px 20px;
  font-size: 13px;
}
.drawer .alert-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  min-height: 350px;
  height: 100%;
}
.drawer .alert-wrap .ico {
  width: 78px;
} */

.drawer {
  position: fixed;
  right: 0;
  top: 0;
  background: #ffffff;
  z-index: 999;
  min-width: 450px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 25px 30px;
  transition: transform 0.3s ease-in-out;
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.1);
}

/* Adjust for smaller screens */
@media (max-width: 768px) {
  .drawer {
    min-width: 100%; /* Full width on mobile */
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .drawer {
    padding: 15px;
  }
}

/* Drawer Size */
.drawer.small {
  width: 540px;
}

@media (max-width: 768px) {
  .drawer.small {
    width: 100%;
  }
}

/* Title Section */
.drawer .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E2E1E8;
  padding-bottom: 12px;
}

.drawer .title .close {
  color: #6F708C;
  font-size: 20px;
  cursor: pointer;
}

.drawer .title .close:hover {
  color: #FF6262;
}

/* Snippet Section */
.drawer form .type-snippet {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.drawer form .type-snippet .snippet {
  display: inline-flex;
  background: #ffffff;
  border: 1px solid #E2E1E8;
  border-radius: 40px;
  padding: 6px 12px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.drawer form .type-snippet .snippet.active {
  background: rgba(226, 225, 232, 0.6);
}

/* Addon Wrap */
.drawer form .addon-wrap {
  background: rgba(226, 225, 232, 0.5);
  padding: 12px;
  border-radius: 10px;
  margin-top: 8px;
}

.drawer form .addon-wrap .form-control {
  height: 36px;
  font-size: 14px;
}

/* Buttons */
.drawer form .btn {
  padding: 10px 24px;
  font-size: 14px;
  border-radius: 6px;
}

/* Alert Wrap */
.drawer .alert-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 350px;
  height: 100%;
  text-align: center;
}

/* Icon */
.drawer .alert-wrap .ico {
  width: 78px;
}

/* Adjustments for smaller screens */
@media (max-width: 600px) {
  .drawer .alert-wrap {
    min-height: 250px;
  }

  .drawer .alert-wrap .ico {
    width: 60px;
  }
}

@media (min-width: 768px) { /* Large screens (MD and above) */
  .custom-padding {
    padding-right: 12%;
  }
}

@media (max-width: 767px) { /* Mobile screens */
  .custom-padding {
    padding-right: 0px;
  }
}



.dish-detail-wrap {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  min-height: 100%;
}
.dish-detail-wrap .dish-img {
  border-radius: 10px;
  width: 100%;
  height: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dish-detail-wrap .dish-img img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.dish-detail-wrap .type {
  display: flex;
  align-items: center;
  color: #4B4764;
  font-size: 13px;
}
.dish-detail-wrap .type img {
  width: 17px;
  margin-right: 8px;
}
.dish-detail-wrap .rating {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: #FBC868;
}
.dish-detail-wrap .rating img {
  width: 22px;
}
.dish-detail-wrap .tags {
  border-bottom: 1px solid #E2E1E8;
}
.dish-detail-wrap .tags span {
  background: rgba(226, 225, 232, 0.7);
  padding: 4px 10px;
  font-size: 13px;
  color: rgba(75, 71, 100, 0.8);
  display: inline-block;
  border-radius: 6px;
  margin-right: 10px;
}
.dish-detail-wrap p {
  line-height: 19px;
}
.dish-detail-wrap p .tiny {
  line-height: 16px;
}
.dish-detail-wrap .addon-text .text {
  display: flex;
  align-items: center;
}
.dish-detail-wrap .addon-text .text .ico {
  width: 18px;
}
.dish-detail-wrap .addon-text .text .price {
  font-size: 13px;
  font-weight: 500;
  color: #4B4764;
}
.dish-detail-wrap .bottom-bar {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #E2E1E8;
  margin-bottom: -10px;
}
.dish-detail-wrap .bottom-bar a {
  text-decoration: none;
  color: #6F708C;
  font-size: 13px;
  display: flex;
  align-items: center;
}
.dish-detail-wrap .bottom-bar a i {
  font-size: 22px;
  margin-right: 5px;
}
.dish-detail-wrap .bottom-bar a:hover {
  color: #4B4764;
}
.dish-detail-wrap .bottom-bar a:hover i {
  color: #4B4764;
}
.dish-detail-wrap .bottom-bar a.danger {
  color: #FF6262;
}
.dish-detail-wrap .bottom-bar a.danger i {
  color: #FF6262;
}
.dish-detail-wrap .bottom-bar a.danger:hover {
  color: #f15050;
}
.dish-detail-wrap .bottom-bar a.danger:hover i {
  color: #f15050;
}

.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  min-height: 400px;
  height: calc(100vh - 210px);
}
.no-data .img-block {
  width: 250px;
  height: auto;
  text-align: center;
}
.no-data .img-block img {
  max-width: 100%;
}

.modal-dialog .btn-close {
  --bs-btn-close-color: $White;
  position: absolute;
  right: -20px;
  top: 0;
  background-size: 10px;
  -moz-filter: invert(1);
  filter: invert(1);
  opacity: 1;
}
.modal-dialog .btn-close:focus {
  box-shadow: none;
  outline: none;
}
.modal-dialog .order-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.modal-dialog .item-detail .dish {
  display: flex;
  align-items: center;
}
.modal-dialog .item-detail .dish .thumbnail {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-dialog .item-detail .dish .thumbnail img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.modal-dialog .item-detail .dish p {
  width: calc(100% - 38px);
  padding-left: 10px;
}
.modal-dialog .new-order-ftr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  flex-flow: row;
}
.modal-dialog .new-order-ftr .btn {
  width: 49%;
}
.modal-dialog .new-order-ftr .btn-danger {
  background: transparent;
  border-color: transparent;
  color: #FF6262;
}
.modal-dialog .new-order-ftr .btn-danger:hover {
  color: #ffffff;
}

.bill-toggle .accordion-item {
  border: none;
}
.bill-toggle .accordion-item .accordion-button {
  background: rgba(226, 225, 232, 0.4);
  border: none;
  padding: 15px;
}
.bill-toggle .accordion-item .accordion-button:focus {
  box-shadow: none;
  outline: none;
}
.bill-toggle .accordion-item .accordion-button:after {
  background-size: 15px;
  width: 15px;
  height: 15px;
  opacity: 0.7;
}
.bill-toggle .accordion-item .accordion-button h6 {
  color: #4B4764;
}
.bill-toggle .accordion-item .accordion-body {
  background: rgba(226, 225, 232, 0.4);
  border-top: 1px solid #E2E1E8;
  padding: 15px;
  border-radius: 0 0 8px 8px;
}
.bill-toggle .accordion-item .accordion-body .amount {
  display: flex;
  align-items: center;
  justify-content: end;
}

.prep-time {
  position: relative;
}
.prep-time .form-label {
  position: absolute;
  top: 12px;
  left: 20px;
  margin-bottom: 0;
  pointer-events: none;
}
.prep-time .form-select {
  padding-left: 20.5rem;
}

.nav-pills .nav-item {
  margin-right: 10px;
}
.nav-pills .nav-link {
  background: rgba(226, 225, 232, 0.45);
  color: #9FA1BE;
  font-size: 13px;
  border-radius: 40px;
}
.nav-pills .nav-link.active {
  background: #5DA571;
}

.order-snippet {
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #E2E1E8;
  padding: 20px 30px;
}
.order-snippet .os-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.order-snippet .os-head .order-by {
  display: flex;
  align-items: center;
}
.order-snippet .os-head .order-by .avatar {
  display: flex;
  align-items: center;
}
.order-snippet .os-head .order-by .avatar .thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.order-snippet .os-head .order-by .avatar .thumbnail img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.order-snippet .os-head .order-by .avatar p {
  line-height: 14px;
}
.order-snippet .os-head .order-by .action-item {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  color: #5DA571;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
}
.order-snippet .os-head .order-by .action-item span {
  font-size: 20px;
}
.order-snippet .os-head .order-by .action-item:hover {
  background: rgba(93, 165, 113, 0.15);
}
.order-snippet .os-body .bg-blk {
  background: rgba(226, 225, 232, 0.45);
  border-radius: 6px;
  padding: 20px;
}
.order-snippet .os-body .bg-blk .dish {
  display: flex;
  align-items: center;
}
.order-snippet .os-body .bg-blk .dish .thumbnail {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.order-snippet .os-body .bg-blk .dish .thumbnail img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.order-snippet .os-body .accordion-button {
  padding: 20px;
}
.order-snippet .os-body .accordion-button h6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 30px);
}
.order-snippet .os-body .ride {
  display: flex;
  align-items: center;
}
.order-snippet .os-body .ride p {
  line-height: 16px;
}
.order-snippet .os-body .ride .avatar {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.order-snippet .os-body .ride .avatar img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.order-snippet .os-body .badge.rating {
  background: #FBC868;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  padding-left: 3px;
}
.order-snippet .os-body .badge.rating img {
  filter: brightness(0) invert(1);
}
.order-snippet .os-body p {
  margin-top: 5px;
  line-height: 15px;
}

.dashboard-section .anime, .dashboard-section .btm-same-height {
  height: 100%;
}
.dashboard-section .recent-order {
  height: 476px;
}
.dashboard-section .recent-order .ro-snippet-wrap {
  height: calc(100% - 35px);
  overflow-y: auto;
}
.dashboard-section .recent-order .ro-snippet-wrap .card-wrap .order-id {
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: #4B4764;
  margin-bottom: 7px;
}
.dashboard-section .recent-order .ro-snippet-wrap .card-wrap .order-id span {
  display: inline-flex;
  align-items: center;
}
.dashboard-section .recent-order .ro-snippet-wrap .card-wrap .order-id span:last-child:after {
  display: none;
}
.dashboard-section .recent-order .ro-snippet-wrap .card-wrap .order-id span:after {
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #6F708C;
  display: inline-block;
  margin: 0 5px;
}
.dashboard-section .recent-order .ro-snippet-wrap .card-wrap .card {
  padding: 10px 15px;
  text-decoration: none;
  border-color: rgba(226, 225, 232, 0.7);
  transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
}
.dashboard-section .recent-order .ro-snippet-wrap .card-wrap .card .item {
  display: block;
  width: 100%;
  font-size: 12px;
  color: #6F708C;
}
.dashboard-section .recent-order .ro-snippet-wrap .card-wrap .card .go-ico {
  font-size: 20px;
  color: #6F708C;
  margin-left: 5px;
}
.dashboard-section .recent-order .ro-snippet-wrap .card-wrap .card:hover {
  background: rgba(226, 225, 232, 0.3);
}
.dashboard-section .recent-order .ro-snippet-wrap .card-wrap .card .badge {
  min-width: 80px;
}
.dashboard-section .snippet-content .dish-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.dashboard-section .snippet-content .dish-card .dish {
  display: flex;
  align-items: center;
  width: 75%;
}
.dashboard-section .snippet-content .dish-card .dish .thumbnail {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard-section .snippet-content .dish-card .dish .thumbnail img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.dashboard-section .snippet-content .dish-card .dish p {
  line-height: 14px;
  display: block;
  width: 100%;
}
.dashboard-section .snippet-content .dish-card .dish .category-ico {
  width: 14px;
  margin-bottom: 3px;
}
.dashboard-section .snippet-content .dish-card .dish .rating {
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 500;
  color: #FBC868;
  margin-top: -2px;
}
.dashboard-section .snippet-content .dish-card .dish .rating .ico {
  width: 8px;
  margin-right: 3px;
}
.dashboard-section .snippet-content .dish-card .dish .rating.with-name {
  font-size: 12px;
}
.dashboard-section .snippet-content .dish-card .dish .rating.with-name span {
  font-size: 13px;
  font-weight: 500;
  color: #4B4764;
  margin-right: 5px;
}
.dashboard-section .snippet-content .dish-card .dish .content {
  width: calc(100% - 70px);
}
.dashboard-section .snippet-content .dish-card .dish .content .review {
  font-size: 11px;
  color: #6F708C;
  line-height: 15px;
  display: inline-block;
  height: 30px;
  overflow: hidden;
}
.dashboard-section .snippet-content .dish-card .price {
  width: 25%;
}
@media only screen and (max-width: 1280px) {
  .dashboard-section .recent-order {
    height: 498px;
  }
}
@media only screen and (max-width: 767px) {
  .dashboard-section .anime {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-flow: row-reverse;
  }
  .dashboard-section .anime .ico-wrap {
    margin-bottom: 0;
  }
  .dashboard-section .anime .count-cont {
    width: calc(100% - 42px);
    padding-right: 20px;
  }
}

.snippet-block {
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(226, 225, 232, 0.7);
  border-radius: 10px;
  padding: 20px;
}
.snippet-block .ico-wrap {
  width: 42px;
  height: 42px;
  background: rgba(226, 225, 232, 0.45);
  border-radius: 50%;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.snippet-block .ico-wrap span {
  font-size: 22px;
  color: #6F708C;
}
.snippet-block .count h4 {
  color: #4B4764;
  display: flex;
  align-items: center;
}
.snippet-block .count h4 i {
  font-size: 20px;
  margin-left: 3px;
}
.snippet-block .count h4 i.up {
  color: #5DA571;
}
.snippet-block .count h4 i.fall {
  color: #FF6262;
}
.snippet-block .head {
  padding: 0;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.snippet-block .head .text-link {
  display: flex;
  align-items: center;
  color: #6F708C;
  font-size: 11px;
  text-transform: uppercase;
  transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
}
.snippet-block .head .text-link i {
  font-size: 18px;
  margin-left: 3px;
}
.snippet-block .head .text-link:hover {
  color: #5DA571;
}
.snippet-block .head h6 small {
  font-size: 12px;
  color: #9FA1BE;
  display: block;
}
.snippet-block.anime {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  cursor: pointer;
  transition: all 0.2s linear;
  -moztransition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
}
.snippet-block.anime:hover {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
  transform: scale(1.02);
}
.snippet-block.anime.blue .ico-wrap {
  background: #DDECFF;
}
.snippet-block.anime.blue .ico-wrap span {
  color: #3E94FF;
}
.snippet-block.anime.blue:hover {
  background: #3E94FF;
  border-color: #3E94FF;
}
.snippet-block.anime.blue:hover .ico-wrap {
  background: #ffffff;
}
.snippet-block.anime.blue:hover h4, .snippet-block.anime.blue:hover i, .snippet-block.anime.blue:hover p {
  color: #ffffff;
}
.snippet-block.anime.green .ico-wrap {
  background: #CFF8ED;
}
.snippet-block.anime.green .ico-wrap span {
  color: #5DA571;
}
.snippet-block.anime.green:hover {
  background: #5DA571;
  border-color: #5DA571;
}
.snippet-block.anime.green:hover .ico-wrap {
  background: #ffffff;
}
.snippet-block.anime.green:hover h4, .snippet-block.anime.green:hover i, .snippet-block.anime.green:hover p {
  color: #ffffff;
}
.snippet-block.anime.red .ico-wrap {
  background: #FDE2E2;
}
.snippet-block.anime.red .ico-wrap span {
  color: #FF6262;
}
.snippet-block.anime.red:hover {
  background: #ff8484;
  border-color: #ff8484;
}
.snippet-block.anime.red:hover .ico-wrap {
  background: #ffffff;
}
.snippet-block.anime.red:hover h4, .snippet-block.anime.red:hover i, .snippet-block.anime.red:hover p {
  color: #ffffff;
}
.snippet-block .no-data {
  height: auto;
  min-height: 260px;
}

.badge.status {
  background: rgba(226, 225, 232, 0.45);
  color: #6F708C;
  font-size: 10px;
  font-weight: normal;
  text-transform: uppercase;
  padding: 4px 10px;
  border-radius: 4px;
}
.badge.status.preparing {
  color: #f4a413;
  background: rgba(254, 186, 62, 0.15);
}
.badge.status.ready {
  color: #3498DB;
  background: rgba(52, 152, 219, 0.15);
}
.badge.status.pickedup {
  color: #9B59B6;
  background: rgba(155, 89, 182, 0.15);
}
.badge.status.delivered {
  color: #5DA571;
  background: rgba(93, 165, 113, 0.15);
}
.badge.status.returned {
  color: #FF6262;
  background: rgba(255, 98, 98, 0.15);
}
.badge.status.rejected {
  color: #4A6682;
  background: rgba(74, 102, 130, 0.15);
}

.settlement-content .change-btn {
  font-size: 13px;
  padding: 5px;
  color: #6F708C;
}
.settlement-content .change-btn i {
  font-size: 20px;
}
.settlement-content .change-btn:hover {
  background: transparent !important;
  border-color: transparent !important;
  color: #5DA571;
}
.settlement-content .add-bank {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 13px;
  color: #5DA571;
  height: 100%;
  border-radius: 10px;
  transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
}
.settlement-content .add-bank:hover {
  background: rgba(93, 165, 113, 0.12);
  color: #5DA571;
}
.settlement-content .snippet-block.empty {
  padding: 0;
}
.settlement-content .snippet-block .custom-table thead th:last-child {
  padding-right: 20px;
}
.settlement-content .snippet-block .custom-table tbody tr td:last-child {
  padding-right: 20px;
}
.settlement-content .snippet-block .custom-table tbody tr td {
  background: rgba(226, 225, 232, 0.2);
  padding-top: 18px;
  padding-bottom: 18px;
}
.settlement-content .snippet-block .custom-table tbody tr p {
  font-size: 13px;
  color: #6F708C;
}
.settlement-content .logo-wrap {
  width: 100px;
  height: 35px;
  justify-content: flex-end;
  display: flex;
}

.settlement-snippet {
  transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
  cursor: pointer;
}
.settlement-snippet img, .settlement-snippet p {
  opacity: 0.5;
}
.settlement-snippet .tick-ico {
  filter: grayscale(1);
  opacity: 0.3;
}
.settlement-snippet.selected img, .settlement-snippet.selected p {
  opacity: 1;
}
.settlement-snippet.selected .tick-ico {
  opacity: 1;
  filter: grayscale(0);
}
.settlement-snippet:hover {
  border-color: #5DA571;
}



.tooltip .tooltip-inner {
  font-size: 12px;
  color: #E2E1E8;
}

.add-bank-form {
  min-height: calc(100vh - 125px);
  padding-bottom: 58px;
  position: relative;
}
.add-bank-form .btn-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #ffffff;
  padding-top: 20px;
}

.settings-content .cover-photo {
  width: calc(100% + 2px);
  height: 210px;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  margin: -1px 0px 0 -1px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.settings-content .cover-photo img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.settings-content .cover-photo .btn-edit {
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 30px;
  border: 1px solid #ffffff;
  background: rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 15px;
  font-size: 13px;
  color: #ffffff;
  z-index: 9;
  transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
}
.settings-content .cover-photo .btn-edit i {
  font-size: 20px;
  margin-right: 5px;
}
.settings-content .cover-photo .btn-edit:before {
  content: "";
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  z-index: -9;
}
.settings-content .cover-photo .btn-edit:hover {
  background: rgba(255, 255, 255, 0.15);
}
.settings-content .text-link {
  font-size: 12px;
}
.settings-content .cover-cont {
  padding: 35px;
}
.settings-content .profile-pic-wrap {
  position: relative;
  z-index: 99;
  margin-top: -100px;
  width: 132px;
}
.settings-content .profile-pic-wrap .pic-wrap {
  width: 132px;
  height: 132px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #ffffff;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.09);
}
.settings-content .profile-pic-wrap .pic-wrap img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.settings-content .profile-pic-wrap .pic-wrap .txt {
  font-size: 32px;
  font-weight: 500;
  color: rgba(159, 161, 190, 0.5);
}
.settings-content .profile-pic-wrap .btn-circle {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
}
.settings-content .profile-pic-wrap .btn-circle i {
  font-size: 22px;
}
.settings-content .personal-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.settings-content .personal-detail .list-inline .list-inline-item {
  display: inline-flex;
  align-items: center;
  font-size: 13px;
  color: #6F708C;
}
.settings-content .personal-detail .list-inline .list-inline-item i {
  font-size: 20px;
  margin-right: 5px;
}
.settings-content .personal-detail .list-inline .list-inline-item:first-child:after {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: rgba(111, 112, 140, 0.7);
  display: inline-block;
  margin-left: 10px;
}
.settings-content .personal-detail .list-inline .list-inline-item:not(:last-child) {
  margin-right: 10px;
}
.settings-content .empty-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(226, 225, 232, 0.5);
  padding: 20px;
  border-radius: 6px;
  color: #9FA1BE;
  font-size: 13px;
  text-decoration: none;
  font-style: italic;
  transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
}
.settings-content .empty-wrap:hover {
  color: #6F708C;
}
.settings-content .progress-count {
  background: #5DA571;
  border-radius: 4px;
  color: #ffffff;
  font-size: 13px;
  font-weight: 600;
  width: 62px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.settings-content .progress-list {
  margin-top: 30px;
  margin-bottom: 0;
}
.settings-content .progress-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #6F708C;
  font-size: 12px;
  margin-bottom: 18px;
}
.settings-content .progress-list li:last-child {
  margin-bottom: 0;
}
.settings-content .progress-list li .ico {
  width: 20px;
  margin-right: 7px;
  filter: grayscale(1);
  opacity: 0.35;
}
.settings-content .progress-list li .level {
  color: #9FA1BE;
  padding-right: 15px;
}
.settings-content .progress-list li.done {
  color: #4B4764;
}
.settings-content .progress-list li.done .level {
  color: #4B4764;
}
.settings-content .progress-list li.done .ico {
  opacity: 1;
  filter: grayscale(0);
}
.settings-content .head-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E2E1E8;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 15px;
}
.settings-content .short-bio p {
  line-height: 18px;
  margin-bottom: 12px;
}
.settings-content .short-bio p .tiny {
  color: #6F708C;
  line-height: inherit;
}
.settings-content .your-cuisine li {
  background: rgba(226, 225, 232, 0.7);
  color: rgba(75, 71, 100, 0.8);
  padding: 5px 10px;
  font-size: 13px;
  border-radius: 6px;
}
.settings-content .snippet-block .text-link {
  font-size: 12px;
  text-transform: none;
  color: #5DA571;
}
.settings-content .snippet-block .text-link:hover {
  color: #2C8E47;
}

.pwd-criteria-list {
  padding: 0;
  list-style-position: inside;
}
.pwd-criteria-list li {
  font-size: 13px;
  color: #6F708C;
  margin-bottom: 10px;
}/*# sourceMappingURL=custom-style.css.map */
.no-overflow {
  overflow: hidden;
}
/* CSS */
.strengths-tags {
  display: flex;
  flex-wrap: wrap;
}

.tag-wrapper {
  display: inline-flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 3px 8px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 15px;
}

.tag-wrapper .tag-text {
  margin-right: 5px;
}

.tag-wrapper button {
  margin-left: auto;
}

.tag input {
  border: 1px solid #ccc;
  padding: 5px;
  margin-bottom: 10px;
}

.text-success {
  color: green;
}
/* help module style  */
.img-fluid-help{
  height: auto;
  max-width: 100%; 
} 
.plan-features ul {
  padding: 0;
  list-style-type: none;
  text-align: left;
}

.plan-features li {
  margin: 0.5rem 0;
}
.plan-box {
  height: 650px; /* Set a fixed height for the card */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures even spacing between content and the button */
  overflow: hidden; /* Prevents content from overflowing */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for better visual distinction */
}

.plan-details, .plan-features {
  flex-grow: 1;
  /* scrollbar-width: 0.3px; */
  overflow-y: auto; /* Allows vertical scrolling if the content overflows */
}

.subscribe-btn {
  margin-top: auto; /* Pushes the button to the bottom */
}

.plan-header {
  margin-bottom: 10px;
}

.plan-box img {
  margin-right: 8px; /* Ensures space between image and text */
}
.bg-light-green {
  background-color: #e8f1ee; /* Light green color */
}
.bg-light-red {
  background-color: #f8d7da; /* Light red color */
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.modal-buttons {
  margin-top: 20px;
}

.modal-buttons button {
  margin: 0 10px;
}

/* General Section Styles */
.policy-container {
  max-width: 1920px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
}

/* Heading Styles */
.policy-container h3 {
  font-size: 22px;
  font-weight: bold;
  color: #333;
}

.policy-container h5 {
  font-size: 18px;
  color: #444;
  font-weight: 600;
}

/* Paragraph Styles (text justification) */
.policy-container p {
  text-align: justify;
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 15px;
}

/* List Styles */
.policy-container ul {
  padding-left: 20px;
  list-style-type: disc;
}

.policy-container li {
  font-size: 16px;
  color: #555;
  margin-bottom: 15px;
}

.policy-container li>ul {
  margin-top: 10px;
  /* Add space between the text and nested list */
  padding-left: 20px;
  /* Indentation for nested list */
}

.terms-conditions-container li>ul li {
  margin-bottom: 8px;
  /* Adds space between nested list items */
}

/* Section Spacing */
.policy-container section {
  margin-bottom: 30px;
}
.radio-large {
  width: 17px;
  /* Adjust size as needed */
  height: 17px;
  cursor: pointer;
  /* Optional: Makes the pointer change to a hand when hovered */
}
.circle-icon {
  width: 4px; /* Adjust the size as needed */
  height: 4px; /* Adjust the size as needed */
  background-color: #28a745; /* Change color as needed */
  border-radius: 20%;
  display: inline-block;
}
/* For Mobile View */
@media (max-width: 767px) {
  .youtube-icon, .badge.bg-primary {
    font-size: 0.5rem; /* Reduce the size of the YouTube icon */
  }

  .badge.bg-primary {
    padding: 4px 8px; /* Reduce padding for plan name */
  }
}
.header-dropdown {
  min-width: 300px; /* Makes the dropdown larger */
  font-size: 1rem; /* Increases font size */
  padding: 20px; /* Adds extra padding inside the dropdown */
  background-color: #f1e9e8; /* Soft background color */
  border-radius: 8px; /* Rounded corners for a card-like effect */
  border: 1px solid #ea1818; /* Gray border color */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Light shadow for a card effect */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

/* For smooth hover effects on list items */
.dropdown-menu li {
  transition: background-color 0.3s ease;
}



.dropdown-menu li strong {
  color: #007b5e; /* Slightly darker text color for emphasis */
}

/* For the PlanUnsubscribe component */
.dropdown-menu .unsubscribe {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #007b5e;
  color: white;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-menu .unsubscribe:hover {
  background-color: #083624; /* Darker green on hover */
}

.text-danger-light {
  color: rgba(220, 53, 69, 0.6); /* Lighter shade of Bootstrap danger color */
  font-weight: normal;
}
/* Container styling */
.trial-plan-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 12px; /* Subtle rounding of container */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}
.plan-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically if container has fixed height */
}


/* Heading */
.trial-plan-heading {
  text-align: center;
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
}

/* Input group styling */
.input-group {
  margin-bottom: 20px;
}

/* Label styling */
.label {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
  display: block;
}

/* Input field styling */
.input-field {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px; /* Soft rounded corners */
  font-size: 16px;
  color: #333;
  background-color: #f9f9f9;
  margin-top: 5px;
  box-sizing: border-box; /* Ensure padding doesn't affect overall width */
}

/* Focus styling for input fields */
.input-field:focus {
  border-color: #007BFF;
  outline: none;
}

/* Card element container */
.card-element-container {
  margin-bottom: 20px;
}

/* Stripe card element styling */
.card-element {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px; /* Matching border radius for consistency */
  font-size: 16px;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

/* Checkbox container for auto charge option */
.auto-charge-container {
  margin-bottom: 20px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.checkbox-input {
  margin-right: 10px;
}

/* Submit button styling */
.submit-btn {
  width: 100%;
  padding: 14px;
  background-color: #5DA571 ;
  color: rgb(200, 241, 175);
  border: none;
  border-radius: 8px; /* Soft rounded corners for button */
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-btn:disabled {
  background-color: #E2E1E8;
  cursor: not-allowed;
}

.submit-btn:hover {
  background-color: #b6b6b6;
}

/* Spinner for loading state */
.loading-spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #E2E1E8;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.plan-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.detail-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-item strong {
  margin-right: 10px; /* Add some space between label and value */
}

.detail-item span {
  text-align: right; /* Align the values to the right for a cleaner layout */
}

.text-justify {
  text-align: justify;
}
.card-footer-subscription {
  background: none !important; /* No background */
  border: none !important;    /* Removes border/line */
  padding: 0;                 /* Optional: Adjust padding */
}
