@import './variables';

body {
    font-family: $Inter-font-family;
    // height: 100vh;
    // overflow: hidden;    

    @media(max-width: 567px){
        padding: 0;        
    }
}

h1 {
    font-size: 1.5rem; // 24px
    line-height: 2.63rem; // 33px
    color: $dark_grey;
    font-weight: 400;

    span {
        color: $primary;
        &:last-child{
            color: $dark_grey;
            display: block;
        }
    }
}

h2 {
    font-size: 1.375rem; // 24px
    line-height: 2.063rem; // 33px
    color: $dark_grey;
    font-weight: 400;
    span{
        color: $primary;
    }
}

h3 {
    font-size: 1.25rem; // 20px
    line-height: 1.4rem; // 30px
    color: $dark_grey;
    font-weight: 400;
}

h4 {
    font-size: 1.125rem; // 20px
    line-height: 1.375rem; // 32px
    color: $dark_grey;
    font-weight: 400;
}

h5 {
    font-size: 1rem; // 16px
    line-height: 1.188rem; // 20px
    color: $dark_grey;
    font-weight: 400;
}

h6 {
    font-size: 0.875rem; // 14px
    line-height: 1rem; // 16px
    color: $dark_grey;
    font-weight: 400;
}

p {
    font-size: 0.875rem; // 14px
    line-height: 1rem; // 16px
    color: $dark_grey;
}

.italic{
    font-style: italic;
}

.small-font {
    font-size: 0.813rem; // 13px
    line-height: 1.2rem; // 16px
    color: $mid_grey;
    opacity: 0.8;
}
.tiny-font {
    font-size: 0.75rem; // 14px
    line-height: 0.9rem; // 16px
    color: $mid_grey;
    opacity: 0.8;
    a{
        color: $mid_grey;
        &:hover{
            text-decoration: none;
        }
    }
}
.medium {
    font-weight: 500;
}
a {
    transition: all 0.3s linear;
}
//button style
.outline-btn{
    font-size: 0.875rem;
    color: $mid_grey;
    height: 45px;
    display: flex;
    align-items: center;
    border-radius: 40px;
    border: 1px solid transparent;
    background: $stroke_grey;
    &:hover{
        border-color: $mid_grey;
        color: $mid_grey;        
    }
}
.primary-btn{
    font-size: 0.875rem;
    color: $white;
    height: 45px;
    display: flex;
    align-items: center;
    border-radius: 40px;
    background-color: $primary;
    &:hover{
        background-color: rgba($primary, 0.9);
        color: $white;
    }
}
.input-group {
    border-radius: 30px;
    border: 1px solid $stroke_grey;
    overflow: hidden;
    .input-group-text {
        background-color: transparent;
        border: none;
        font-size: 0.875rem;
        color: $mid_grey;

        img {
            margin-right: 4px;
        }
    }
    .form-control {
        border-top: none;
        border-bottom: none;
        border-right: none;
        padding-left: 16px;
        @media(max-width:991px){
            margin-bottom: 0 !important;
        }
    }
}
::-webkit-input-placeholder {
    color: $dark_grey;
    font-size: 0.875rem;
}

::-moz-placeholder {
    color: $dark_grey;
    font-size: 0.875rem;
}

/* firefox 19+ */
:-ms-input-placeholder {
    color: $dark_grey;
    font-size: 0.875rem;
}

/* ie */
input:-moz-placeholder {
    color: $dark_grey;
    font-size: 0.875rem;
}

.form-control,
.form-select {
    border-color: $stroke_grey;
    border-radius: 30px;
    height: 47px;
    padding-left: 24px;
    font-size: 0.875rem;
    &:focus{
        box-shadow: none;
        border-color: $stroke_grey;
    }
    @media(max-width:991px){
        margin-bottom: 16px;
    }
}

//Signup style

.signup-sec {
    height: 100vh;
    overflow:hidden;
    @media (max-width: 991px){
        height: auto;
        overflow: scroll;
    }
    .logo-wrap {
        width: 112px;
    }

    .signup-img {
        height: calc(100vh - 30px);
        padding: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .right-form {
        height: calc(100vh - 30px);
        overflow-y: auto;
        @media (max-width: 991px){
            padding-left: 30px !important;
            margin-right: 0;
            padding-right: 30px !important;
            overflow: hidden;
            height: auto;
            .form-control{
                margin-bottom: 16px;
            }
        }
        @media(max-width: 767px){
            padding-left: 10px !important;
            padding-right: 10px !important;    
            margin-right: 0;
            align-items: center;
            h1 {
                text-align: left;
                font-size: 20px;
            }
        }
        .signup-action{
            @media(max-width: 567px){
                flex-direction: column;
                text-align: center;
                align-items: center;
            }
        }
    }
}

.file-input {
    position: relative;
    width: 100%;
    border: 1px dashed $stroke_grey;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 109px;
    cursor: pointer;
    border-radius: 10px;
    label{
        font-size: 0.875rem;
        color: $mid_grey;
    }
    input {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

.help-text {
    font-size: 0.688rem;
    color: $grey;
}


.footer-login{
    border-top: 1px solid $stroke_grey;

}
.footer-style{
    border-top: 1px solid $stroke_grey;
    background-color: #fcfcff;

}

.link{
    color: $primary;
    text-decoration: underline;
    font-size: 0.875rem;
    &:hover{
        text-decoration: none;
        color: $primary;
    }
}

.verification-content{
    @media(max-width:991px){
        align-items: center;
        text-align: center;
        p{
            text-align: center !important;
        }
    }
    .verification-input-wrap{
        @media(max-width:991px){
            align-items: center;
            justify-content: center !important;
           
        }
        .verification-input{
            width: 65px;
            height: 65px;
            background-color: rgba($stroke_grey, 0.5);
            border-radius: 4px;
            text-align: center;
            padding: 8px;
            font-size: 1.65rem;
            line-height: 2rem;
            color: $dark_grey;
            border: none;
            @media(max-width:567px){
                width: 45px;
                height: 45px;
                font-size: 1rem;
                line-height: 1.6rem;
            }
            &::placeholder{
                font-size: 1.65rem;
                color: $dark_grey;
                opacity: 0.2;
                text-align: center;
                line-height: 2rem;
                @media(max-width:567px){
                    font-size: 1rem;
                    line-height: 1.56rem;
                }
            }
        }
    }
   
}


.login-logo{
    width: 112px;
}

.ss-ico {
    a {
        padding: 5px;
        margin: 0 5px;
        .ico {
            margin-top: -3px;
            width: 28px;
            height: 28px;
            &.google {
                margin-top: 0;
                width: 26px;
                height: 26px;
            }
            &.fb {
                margin-top: 0;
                width: 30px;
                height: 30px;
            }
        }
    }
}

.choose-device {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: column;
    height: calc(100vh - 40px);
    .snippet-block {
        width: 420px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left-wrap {
            display: flex;
            align-items: center;
            .ico-wrap {
                background: rgba($primary, 0.2);
                margin-bottom: 0;
                margin-right: 15px;
                span {
                    color: $primary;
                }
            }
        }
    }
    @media(max-width:767px){
        .snippet-block {
            width: 100%;
        }
    }
}

.vh-100 {
    @media(max-width:767px){
        min-height: 100vh;
    }
}