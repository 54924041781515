

//Fonts
$Inter-font-family: 'Inter', sans-serif;

//Colors
$white: #ffffff;
$black: #000000;
$primary:#5DA571;
$dark_grey: #4B4764;
$mid_grey: #6F708C;
$grey: #9FA1BE;
$stroke_grey: #E2E1E8;
$warning: #FBC868;
$danger: #FF6262;
