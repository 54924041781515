body {
  font-family: "Inter", sans-serif;
}
@media (max-width: 567px) {
  body {
    padding: 0;
  }
}

h1 {
  font-size: 1.5rem;
  line-height: 2.63rem;
  color: #4B4764;
  font-weight: 400;
}
h1 span {
  color: #5DA571;
}
h1 span:last-child {
  color: #4B4764;
  display: block;
}

h2 {
  font-size: 1.375rem;
  line-height: 2.063rem;
  color: #4B4764;
  font-weight: 400;
}
h2 span {
  color: #5DA571;
}

h3 {
  font-size: 1.25rem;
  line-height: 1.4rem;
  color: #4B4764;
  font-weight: 400;
}

h4 {
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: #4B4764;
  font-weight: 400;
}

h5 {
  font-size: 1rem;
  line-height: 1.188rem;
  color: #4B4764;
  font-weight: 400;
}

h6 {
  font-size: 0.875rem;
  line-height: 1rem;
  color: #4B4764;
  font-weight: 400;
}

p {
  font-size: 0.875rem;
  line-height: 1rem;
  color: #4B4764;
}

.italic {
  font-style: italic;
}

.small-font {
  font-size: 0.813rem;
  line-height: 1.2rem;
  color: #6F708C;
  opacity: 0.8;
}

.tiny-font {
  font-size: 0.75rem;
  line-height: 0.9rem;
  color: #6F708C;
  opacity: 0.8;
}
.tiny-font a {
  color: #6F708C;
}
.tiny-font a:hover {
  text-decoration: none;
}

.medium {
  font-weight: 500;
}

a {
  transition: all 0.3s linear;
}

.outline-btn {
  font-size: 0.875rem;
  color: #6F708C;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 40px;
  border: 1px solid transparent;
  background: #E2E1E8;
}
.outline-btn:hover {
  border-color: #6F708C;
  color: #6F708C;
}

.primary-btn {
  font-size: 0.875rem;
  color: #ffffff;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 40px;
  background-color: #5DA571;
}
.primary-btn:hover {
  background-color: rgba(93, 165, 113, 0.9);
  color: #ffffff;
}

.input-group {
  border-radius: 30px;
  border: 1px solid #E2E1E8;
  overflow: hidden;
}
.input-group .input-group-text {
  background-color: transparent;
  border: none;
  font-size: 0.875rem;
  color: #6F708C;
}
.input-group .input-group-text img {
  margin-right: 4px;
}
.input-group .form-control {
  border-top: none;
  border-bottom: none;
  border-right: none;
  padding-left: 16px;
}
@media (max-width: 991px) {
  .input-group .form-control {
    margin-bottom: 0 !important;
  }
}

::-webkit-input-placeholder {
  color: #4B4764;
  font-size: 0.875rem;
}

::-moz-placeholder {
  color: #4B4764;
  font-size: 0.875rem;
}

/* firefox 19+ */
:-ms-input-placeholder {
  color: #4B4764;
  font-size: 0.875rem;
}

/* ie */
input:-moz-placeholder {
  color: #4B4764;
  font-size: 0.875rem;
}

.form-control,
.form-select {
  border-color: #E2E1E8;
  border-radius: 30px;
  height: 47px;
  padding-left: 24px;
  font-size: 0.875rem;
}
.form-control:focus,
.form-select:focus {
  box-shadow: none;
  border-color: #E2E1E8;
}
@media (max-width: 991px) {
  .form-control,
  .form-select {
    margin-bottom: 16px;
  }
}

.signup-sec {
  height: 100vh;
  overflow: hidden;
}
@media (max-width: 991px) {
  .signup-sec {
    height: auto;
    overflow: scroll;
  }
}
.signup-sec .logo-wrap {
  width: 112px;
}
.signup-sec .signup-img {
  height: calc(100vh - 30px);
  padding: 0;
}
.signup-sec .signup-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.signup-sec .right-form {
  height: calc(100vh - 30px);
  overflow-y: auto;
}
@media (max-width: 991px) {
  .signup-sec .right-form {
    padding-left: 30px !important;
    margin-right: 0;
    padding-right: 30px !important;
    overflow: hidden;
    height: auto;
  }
  .signup-sec .right-form .form-control {
    margin-bottom: 16px;
  }
}
@media (max-width: 767px) {
  .signup-sec .right-form {
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-right: 0;
    align-items: center;
  }
  .signup-sec .right-form h1 {
    text-align: left;
    font-size: 20px;
  }
}
@media (max-width: 567px) {
  .signup-sec .right-form .signup-action {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
}

.file-input {
  position: relative;
  width: 100%;
  border: 1px dashed #E2E1E8;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 109px;
  cursor: pointer;
  border-radius: 10px;
}
.file-input label {
  font-size: 0.875rem;
  color: #6F708C;
}
.file-input input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.help-text {
  font-size: 0.688rem;
  color: #9FA1BE;
}

.footer-login {
  border-top: 1px solid #E2E1E8;
}
.footer-style
{
  border-top: 1px solid #E2E1E8;
  background-color: #fcfcff;
}
.footer-link {
  color: #5DA571;
  text-decoration: underline;
  font-size: 0.875rem;
}
.footer-link:hover {
  text-decoration: none;
  color: #FF6262;
}
.link {
  color: #5DA571;
  text-decoration: underline;
  font-size: 0.875rem;
}
.link:hover {
  text-decoration: none;
  color: #5DA571;
}

@media (max-width: 991px) {
  .verification-content {
    align-items: center;
    text-align: center;
  }
  .verification-content p {
    text-align: center !important;
  }
}
@media (max-width: 991px) {
  .verification-content .verification-input-wrap {
    align-items: center;
    justify-content: center !important;
  }
}
.verification-content .verification-input-wrap .verification-input {
  width: 65px;
  height: 65px;
  background-color: rgba(226, 225, 232, 0.5);
  border-radius: 4px;
  text-align: center;
  padding: 8px;
  font-size: 1.65rem;
  line-height: 2rem;
  color: #4B4764;
  border: none;
}
@media (max-width: 567px) {
  .verification-content .verification-input-wrap .verification-input {
    width: 45px;
    height: 45px;
    font-size: 1rem;
    line-height: 1.6rem;
  }
}
.verification-content .verification-input-wrap .verification-input::-moz-placeholder {
  font-size: 1.65rem;
  color: #4B4764;
  opacity: 0.2;
  text-align: center;
  line-height: 2rem;
}
.verification-content .verification-input-wrap .verification-input::placeholder {
  font-size: 1.65rem;
  color: #4B4764;
  opacity: 0.2;
  text-align: center;
  line-height: 2rem;
}
@media (max-width: 567px) {
  .verification-content .verification-input-wrap .verification-input::-moz-placeholder {
    font-size: 1rem;
    line-height: 1.56rem;
  }
  .verification-content .verification-input-wrap .verification-input::placeholder {
    font-size: 1rem;
    line-height: 1.56rem;
  }
}

.login-logo {
  width: 112px;
}

.ss-ico a {
  padding: 5px;
  margin: 0 5px;
}
.ss-ico a .ico {
  margin-top: -3px;
  width: 28px;
  height: 28px;
}
.ss-ico a .ico.google {
  margin-top: 0;
  width: 26px;
  height: 26px;
}
.ss-ico a .ico.fb {
  margin-top: 0;
  width: 30px;
  height: 30px;
}

.choose-device {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-flow: column;
  height: calc(100vh - 40px);
}
.choose-device .snippet-block {
  width: 420px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.choose-device .snippet-block .left-wrap {
  display: flex;
  align-items: center;
}
.choose-device .snippet-block .left-wrap .ico-wrap {
  background: rgba(93, 165, 113, 0.2);
  margin-bottom: 0;
  margin-right: 15px;
}
.choose-device .snippet-block .left-wrap .ico-wrap span {
  color: #5DA571;
}
@media (max-width: 767px) {
  .choose-device .snippet-block {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .vh-100 {
    min-height: 100vh;
  }
}/*# sourceMappingURL=style.css.map */

/* Video link */
.blinking-icon {
  animation: blinking 1s infinite;
}

@keyframes blinking {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.delete-account {
  color: red;
  cursor: pointer;
}
.small-icon {
  font-size: 18px; /* Adjust the size as needed */
  vertical-align: middle; /* Adjust vertical alignment if necessary */
}